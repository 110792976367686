/*
/*
@File: AndTour - Travel Agency HTML Template

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

1. Common css
2. Heading Area Css
3. Button Area start
4. Home Page
5. About Page
6. Booking Confirmation Page
7. Booking History Page
8. Contact Page
9.  Dashboard Page
10. Error Page
11. Faqs Page
12. Flight-booking Page
13. Flight-booking-submission Page
14. Flight-search-result Page
15. forgot-password Page
16. hotel-booking Page
17. hotel-details Page
18. hotel-search Page
19. login Page
20. my-profile Page
21. news Page
22. news-details Page
23. notification Page
24. privacy-policy Page
25. register Page
26. reset-password Page
27. room-booking Page
28. room-details Page
29. terms-service Page
30. testimonials Page
31. top-destinations Page
32. top-destinations-details Page
33. tour-booking-submission Page
34. tour-details Page
35. tour-guides Page
36. tour-search Page
37. verify-otp Page
38. wallet Page
39. Footer 
40. Preloader


/*================================================
Default CSS
=================================================*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,400;1,900&amp;family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&amp;display=swap');



:root {
    --main-color: #EA0876;
    --white-color: #ffffff;
    --black-color: #2B2540;
    --black-color-opacity: #2b2540c4;
    --paragraph-color: #818090;
    --bg-color: #F3F6FD;
    --transition: .4s all ease-in-out;
}

#preloader {
    position: fixed; /* Ensures the loader stays on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffff; /* White background with transparency */
    z-index: 999900; /* High z-index to cover the entire page */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

html,
body {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.mb-30 {
    margin-bottom: 30px;
}

a {
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out 0.1s;
    transition: all 0.3s ease-in-out 0.1s;
    outline: 0 !important;
    color: var(--main-color) ;
}

a:hover {
    text-decoration: none !important;
    color: var(--heading-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

h1 {
    font-size: 82px;
    font-weight: 400;
    line-height: 90px;
}

h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
}

h3 {
    font-size: 24px;
    font-weight: 300;
}

h4 {
    font-size: 20px;
    font-weight: 500;
}

h5 {
    font-size: 18px;
    font-weight: 400;
}

h6 {
    font-size: 14px;
    font-weight: 400;
}

p {
    font-size: 16px;
    line-height: 28px;
    color: var(--paragraph-color);
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0;
}

p:last-child {
    margin-bottom: 0;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    padding: 0;
}

.nav-item{
    margin: 0 20px !important;
}

/* --Common Style-- */
section {
    position: relative;
}

.form-control {
    height: 50px;
    border: none;
    /* box-shadow: 0px 1px 13px 0px #0000000d; */
    padding: 10px 22px;
    font-size: 16px;
}

.form-control:focus {
    color: var(--heading-color);
    background-color: #fff;
    border: 1px solid var(--main-color);
    outline: none;
    box-shadow: none;
}

.section_padding {
    padding: 100px 0;
}

.section_padding_top {
    padding: 100px 0 0 0;
}

.section_padding_bottom {
    padding: 0 0 100px 0;
}

/* --Heading Area-- */

.heading_left_area h2 {
    font-weight: 400;
    padding-bottom: 17px;
    line-height: 45px;
}

.heading_left_area h2 span {
    display: inline-block;
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 10px;
}

.heading_left_area h5 {
    color: var(--black-color-opacity);
}

.section_heading_center {
    text-align: center;
    padding-bottom: 30px;
}

.section_heading_center h2 {
    position: relative;
    padding-bottom: 15px;
}

.section_heading_center h2:after {
    content: "";
    position: absolute;
    width: 200px;
    height: 1px;
    background: var(--main-color);
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
}

.img_animation {
    overflow: hidden;
}

.img_animation img {
    transition: var(--transition);
    position: relative;
}

.img_animation::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #8b3eea9c;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);
}

.img_animation:hover::after {
    opacity: 1;
    visibility: visible;
}

.img_animation:hover img {
    transform: scale(1.2);
}

.slick-prev,
.slick-next {
    display: none !important;
}

/* --Button Area start-- */
.btn-check:focus+.btn,
.btn:focus {
    outline: none;
    box-shadow: none
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.btn:hover {
    color: var(--white-color);
}

.btn-six:hover {
    color: var(--black-color);
}

.btn_theme {
    color: var(--white-color);
    background-color: var(--main-color);
    transition: var(--transition);
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
    border: none
}

.btn_theme:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 580px;
    height: 550px;
    margin: auto;
    background: var(--black-color);
    border-radius: 50%;
    z-index: -1;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: translateX(-50%) translateY(-5%) scale(.4);
    transform: translateX(-50%) translateY(-5%) scale(.4);
    transition: var(--transition);

}

.btn_theme:hover:before {
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform: translateX(-45%) translateY(0) scale(1);
    transform: translateX(-50%) translateY(0) scale(1);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    border: none;
}

.btn_theme_white {
    color: var(--black-color);
    background-color: var(--white-color);
    transition: var(--transition);
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
    border: 1px solid var(--white-color);
}

.btn_theme_white:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 580px;
    height: 550px;
    margin: auto;
    background: var(--black-color);
    border-radius: 50%;
    z-index: -1;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: translateX(-50%) translateY(-5%) scale(.4);
    transform: translateX(-50%) translateY(-5%) scale(.4);
    transition: var(--transition);

}

.btn_theme_white:hover:before {
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform: translateX(-45%) translateY(0) scale(1);
    transform: translateX(-50%) translateY(0) scale(1);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    border: none;
}

.btn_theme_transparent {
    color: var(--white-color);
    background-color: transparent;
    transition: var(--transition);
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
    border: 1px solid var(--white-color);
}


.btn_theme_transparent:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 580px;
    height: 550px;
    margin: auto;
    background: var(--black-color);
    border-radius: 50%;
    z-index: -1;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: translateX(-50%) translateY(-5%) scale(.4);
    transform: translateX(-50%) translateY(-5%) scale(.4);
    transition: var(--transition);

}

.btn_theme_transparent:hover:before {
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform: translateX(-45%) translateY(0) scale(1);
    transform: translateX(-50%) translateY(0) scale(1);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    border: none;
}


.btn_md {
    padding: 12px 35px;
    font-size: 18px;
}

.btn_sm {
    font-size: 14px;
    padding: 5px 18px;
}

.btn_navber {
    color: #fff;
    border: 2px solid var(--main-color);
    padding: 7px 10px;
    transition: var(--transition);
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.btn_navber:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 550px;
    height: 550px;
    margin: auto;
    border-radius: 50%;
    z-index: -1;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    background: var(--main-color);
    -webkit-transform: translateX(-51%) translateY(27%) scale(.8);
    transform: translateX(-51%) translateY(27%) scale(.8);
    transition: var(--transition);
}

.btn_navber:hover:before {
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transform: translateX(-42%) translateY(-57%) scale(4);
    transform: translateX(-42%) translateY(-57%) scale(4);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
}

.is-sticky .btn_navber {
    border: 1px solid var(--white-color);
}

.is-sticky .btn-six {
    border: 1px solid var(--white-color);
    color: var(--white-color);
}

/* =========================
        Home Page
=========================*/

/* --Banner Area-- */
#home_one_banner {
    background-image: url("../img/banner/bg.jpg");
    padding: 370px 0 280px 0;
    position: relative;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



.banner_one_text {
    text-align: center;
}

.banner_one_text h1 {
    color: var(--white-color);
}

.banner_one_text h3 {
    color: var(--white-color);
    padding-top: 13px;
}

/* --Theme Search Form-- */
#theme_search_form {
    margin-top: -140px;
    z-index: 9;
}

.theme_search_form_area {
    background: #FFFFFF;
    box-shadow: 4px 14px 28px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 30px 30px;
    position: relative;
    bottom: 100px;
}

.theme_search_form_tabbtn .nav-item {
    margin-right: 20px;
}

.theme_search_form_tabbtn .nav-item:last-child {
    margin-right: 0px;
}

.theme_search_form_tabbtn .nav-tabs {
    border-bottom: none;
}

.theme_search_form_tabbtn .nav-tabs .nav-item.show .nav-link,
.theme_search_form_tabbtn .nav-tabs .nav-link.active {
    color: var(--white-color);
    background-color: var(--main-color);
    border-color: none;
}

.theme_search_form_tabbtn .nav-tabs .nav-link {
    margin-bottom: 0;
    color: var(--black-color);
    border-radius: 6px;
    border: 1px solid var(--main-color);
}

.theme_search_form_tabbtn .nav-tabs .nav-link i {
    padding-right: 10px;
}

.theme_search_form_tabbtn {
    margin-bottom: 30px;
}


.flight_Search_boxed {
    background: #8b3eea17;
    padding: 10px 10px 10px 20px;
    border-radius: 10px;
    position: relative;
}

.plan_icon_posation {
    position: absolute;
    top: 10px;
    right: 30px;
}

.plan_icon_posation i {
    font-size: 24px;
}

.range_plan {
    position: absolute;
    left: -37px;
    top: 50%;
    transform: translateY(-50%);
}

.range_plan i {
    background: #f5eefd;
    font-size: 24px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 46px;
    border-radius: 50%;
    border: 3px solid #fff;
    color: var(--main-color);
}

.flight_Search_boxed p {
    font-size: 14px;
}

.flight_Search_boxed input {
    font-size: 22px;
    width: 87%;
    border: none;
    background: transparent;
    font-weight: 500;
    height: 35px;
    padding: 0;
}

.flight_Search_boxedd{
    background: #8b3eea17;
    padding: 10px 10px 10px 20px;
    border-radius: 10px;
    position: relative;
   
}
.flight_Search_boxedd p{
    font-size: 14px;
}
.tour_search_form .rs-picker-default .rs-picker-toggle.rs-btn{
    background-color: transparent !important;
    border: none;

}

.rs-picker-toggle-value{
    color: black !important;
}

.flight_Search_boxed input:focus-within {
    outline: none;
    border: none;
}

.flight_Search_boxed span {
    display: block;
    font-size: 12px;
    text-overflow: ellipsis;
    /* overflow: hidden; */
    white-space: nowrap;
}

.date_flex_area {
    display: flex;
    justify-content: space-between;
}

.dropdown_passenger_area button {
    border: none;
    background: transparent;
    padding: 0;
    font-size: 22px;
    font-weight: 500;
}

.dropdown_passenger_area button::after {
    display: none;
}

.dropdown_passenger_area .dropdown-menu.show {
    transform: translate(-56%, 70px) !important;
}

.dropdown_passenger_area .dropdown-menu {
    z-index: 1000;
    min-width: 350px;
    padding: 15px 20px;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0.25rem;
}

.traveller-calulate-persons .passengers .passengers-types .passengers-type {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    justify-content: space-between;
}

.traveller-calulate-persons .passengers .passengers-types .passengers-type .text {
    align-items: center;
    display: flex;
}

.traveller-calulate-persons .passengers .passengers-types .passengers-type .text .count {
    margin-right: 20px;
    width: 24px;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 600;
}

.traveller-calulate-persons .passengers .passengers-types .passengers-type .text .type-label span {
    font-size: 12px;
    color: #4c4c4c;
}

.traveller-calulate-persons .passengers .passengers-types .passengers-type .button-set button {
    font-size: 12px;
    color: #4c4c4c;
    border: 1px solid #d8d8d8;
    width: 20px;
    height: 20px;
}

.traveller-calulate-persons .passengers .passengers-types {
    padding-top: 15px;
}

.traveller-calulate-persons .passengers h6 {
    font-size: 16px;
    font-weight: 500;
    color: var(--main-color);
}

.traveller-calulate-persons .cabin-selection {
    padding-top: 10px;
    border-top: 1px solid #c7c7cc;
    margin-top: 20px;
}

.traveller-calulate-persons .label-select-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 10px;
}

.traveller-calulate-persons .label-select-btn:hover {
    background: var(--main-color);
    color: var(--white-color);
}

.traveller-calulate-persons .label-select-btn.active {
    background: var(--main-color);
    color: var(--white-color);
}


.traveller-calulate-persons .muiButton-label {
    font-size: 14px;
}

.traveller-calulate-persons .cabin-selection h6 {
    padding: 15px 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--main-color);
}

.top_form_search_button {
    text-align: center;
    margin-top: 30px;
}

.flight_categories_search .nav-tabs {
    border-bottom: none;
}

.flight_categories_search .nav-tabs .nav-item.show .nav-link,
.flight_categories_search .nav-tabs .nav-link.active {
    color: var(--main-color);
    border-color: none;
}

.flight_categories_search .nav-tabs .nav-link {
    margin-bottom: 0;
    color: var(--black-color);
    border-radius: 6px;
    border: none;
    font-weight: 500;
    background: transparent;
}

.flight_categories_search {
    position: absolute;
    right: 30px;
    top: 25px;
}

.multi_city_form {
    margin-bottom: 15px;
}


.add_multy_form {
    text-align: right;
}

.add_multy_form button {
    color: var(--main-color);
    border: none;
    background: transparent;
}

.multi_form_remove {
    text-align: right;
}

.multi_form_remove button {
    color: red;
    border: none;
    background: transparent;
    padding-bottom: 10px;
}



@media (max-width: 1399px) {

    #theme_search_form .container,
    #theme_search_form .container-lg,
    #theme_search_form .container-md,
    #theme_search_form .container-sm,
    #theme_search_form .container-xl {
        max-width: 100%;
    }

    #theme_search_form_five .container,
    #theme_search_form_five .container-lg,
    #theme_search_form_five .container-md,
    #theme_search_form_five .container-sm,
    #theme_search_form_five .container-xl {
        max-width: 100%;
    }

    #theme_search_form_eight .container,
    #theme_search_form_eight .container-lg,
    #theme_search_form_eight .container-md,
    #theme_search_form_eight .container-sm,
    #theme_search_form_eight .container-xl {
        max-width: 100%;
    }
    #theme_search_form_eleven .container,
    #theme_search_form_eleven .container-lg,
    #theme_search_form_eleven .container-md,
    #theme_search_form_eleven .container-sm,
    #theme_search_form_eleven .container-xl {
        max-width: 100%;
    }
}


/* --Imagination Area-- */
.imagination_boxed {
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    transition: var(--transition);
}

.imagination_boxed img {
    position: relative;
    width: 100%;
    object-fit: cover;
    height: 23rem;
    transition: var(--transition);
}


.imagination_boxed:hover img {
    transform: scale(1.2);
}

.imagination_boxed:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 25%;
    left: 0;
    background: #17161669;
    bottom: 0;
    z-index: 999;
    transition: all 0.5s;
    transform: translate(0%, 100%);
}

.imagination_boxed:hover:before {
    transform: translate(0%, 0%);
}

.imagination_boxed h3 {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 999;
    align-items: center;
    height: 25%;
}

.imagination_boxed h3 span {
    display: block;
    text-align: center;
}

.imagination_boxed h3 a {
    color: var(--white-color);
    transition: var(--transition);
}

.imagination_boxed:hover h3 a {
    font-weight: 500;
}

/* --Top Destinations Area-- */
.destinations_content_box {
    position: relative;
    margin-bottom: 23px;
    border-radius: 12px;
    overflow: hidden;
}

.destinations_content_box .btn {
    margin-top: 2px;
}

.destinations_content_box img {
    width: 100%;
}

.destinations_content_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.destinations_content_inner h3 a {
    color: var(--white-color);
}

.destinations_content_inner h3 a:hover {
    color: var(--black-color);
}

.destinations_content_inner h2 {
    color: var(--white-color);
    padding-bottom: 20px;
}

.destinations_big_offer h1 {
    color: var(--white-color);
    font-size: 150px;
    line-height: 109px;
    padding-bottom: 20px;
}

.destinations_big_offer {
    display: flex;
}

.destinations_big_offer h6 {
    color: var(--white-color);
    line-height: 45px;
    padding-top: 8px;
}

.destinations_big_offer h6 span {
    display: block;
    font-size: 38px;
    font-weight: 500;
}

/* -- Explore Area -- */

.theme_nav_tab {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgb(139 62 234 / 8%), 4px 14px 28px rgb(139 148 250 / 7%);
    border-radius: 12px;
    padding: 21px 0;
    margin-bottom: 30px;
}

.theme_nav_tab_item .nav-tabs {
    border-bottom: none;
    justify-content: center;
}

.theme_nav_tab_item .nav-tabs .nav-item.show .nav-link,
.theme_nav_tab_item .nav-tabs .nav-link.active {
    color: var(--white-color);
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.theme_nav_tab_item .nav-tabs .nav-link {
    margin-bottom: 0;
    background: 0 0;
    border: 1px solid var(--main-color);
    color: var(--black-color);
    padding: 8px 30px;
    border-radius: 5px;
    margin-right: 35px;
}

.theme_nav_tab_item .nav-tabs .nav-link:last-child {
    margin-right: 0;
}

.theme_common_box_two {
    border-radius: 11px;
    overflow: hidden;
    border: 1px solid #d2d2d275;
    transition: var(--transition);
    margin-bottom: 30px;
}

.theme_common_box_two:hover {
    box-shadow: 4px 14px 28px rgba(0, 0, 0, 0.1);
}

.theme_two_box_img {
    position: relative;
    overflow: hidden;
}

.img_hover {
    overflow: hidden;
}

.img_hover img {
    transition: var(--transition);
    width: 100%;
}

.img_hover:hover img {
    transform: scale(1.2);
}

.theme_two_box_img img {
    width: 100%;
}

.theme_two_box_img .discount_tab {
    position: absolute;
    right: 9px;
    bottom: -17px;
    background: #FFFFFF;
    box-shadow: 4px 5px 12px rgb(0 0 0 / 10%);
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
}

.theme_two_box_img p {
    color: var(--white-color);
    position: absolute;
    bottom: 4px;
    left: 13px;
    font-size: 14px;
}

.theme_two_box_img p i {
    padding-right: 5px;
}

.theme_two_box_content {
    background: #fff;
    padding: 20px 15px;
}

.theme_two_box_content h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.theme_two_box_content h4 a {
    color: var(--black-color);
}

.theme_two_box_content h4 a:hover {
    color: var(--main-color);
}

.theme_two_box_content p {
    padding: 10px 0px;
    font-size: 14px;
}

.theme_two_box_content p .review_rating {
    color: var(--main-color);
}

.theme_two_box_content p .review_count {
    margin-left: 10px;
}

.theme_two_box_content h3 {
    font-weight: 500;
}

.theme_two_box_content h3 span {
    font-weight: 400;
    font-size: 14px;
}

/* --offer Area-- */
.offer_area_box {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.offer_area_box img {
    width: 100%;
}

.offer_area_box .offer_area_content {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 30px 27px;
    z-index: 999;
}


.offer_area_content h2 {
    color: var(--white-color);
    padding-bottom: 20px;
}

.offer_area_content p {
    color: var(--white-color);
    padding-bottom: 25px;
}

/* --Promotional Tours Area-- */
.dot_style .owl-nav.disabled+.owl-dots {
    margin-top: 0px;
}

.dot_style.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 5px;
    background: #D6D6D6;
    display: block;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.dot_style.owl-theme .owl-dots .owl-dot.active span,
.dot_style.owl-theme .owl-dots .owl-dot:hover span {
    background: var(--main-color);
}

/* --Destinations Area-- */
.tab_destinations_boxed {
    display: flex;
    align-items: center;
    border: 1px solid #d2d2d2;
    border-radius: 9px;
    overflow: hidden;
    transition: var(--transition);
    margin-bottom: 40px;
}

.tab_destinations_boxed:hover {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.08), 4px 6px 16px rgba(0, 0, 0, 0.08);
}

.tab_destinations_conntent {
    padding-left: 20px;
}

.tab_destinations_conntent h3 {
    font-size: 22px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tab_destinations_conntent h3 a {
    color: var(--black-color);
}

.tab_destinations_conntent h3 a:hover {
    color: var(--main-color);
}

.tab_destinations_conntent p {
    color: var(--black-color);
    font-weight: 500;
    padding-top: 5px;
}

.tab_destinations_conntent p span {
    color: var(--main-color);
}

/* --News Area-- */
.home_news_item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.home_news_img {
    width: 100px;
    height: 90px;
    border-radius: 10px;
    overflow: hidden;
}

.home_news_left_wrapper .home_news_item:last-child {
    margin-bottom: 0;
}

.slick-slide img {
    display: block;
    border-radius: 10px;
}

.home_news_img img {
    width: 100%;
}

.home_news_content {
    width: 68%;
    padding-left: 25px;
}

.home_news_content h3 {
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
}

.home_news_content h3 a {
    color: var(--black-color);
}

.home_news_content h3 a:hover {
    color: var(--main-color);
}

.home_news_content p {
    padding-top: 12px;
    font-size: 14px;
}

.home_news_content p span {
    padding-left: 10px;
}

.home_news_content p span i {
    padding-top: 0;
    font-size: 7px;
    padding-right: 10px;
}

.seeall_link a i {
    padding-left: 5px;
}

.home_news_big img {
    width: 100%;
}

.home_news_big h3 {
    padding-top: 22px;
    font-size: 22px;
    font-weight: 500;
}

.home_news_big h3 a {
    color: var(--black-color);
    padding-top: 0;
}

.home_news_big h3:hover a {
    color: var(--main-color);
}

.home_news_big p {
    padding-top: 8px;
}

.home_news_big a {
    display: block;
    padding-top: 11px;
}

#our_partners .owl-carousel .owl-item img {
    width: auto !important;
    filter: opacity(0.5);
}

#our_partners .owl-carousel .owl-item img:hover {
    filter: opacity(1);
}

.news_home_bigest {
    overflow: hidden;
    border-radius: 12px;
}

/* --Cta Area-- */
#cta_area {
    background: var(--bg-color);
    padding: 70px 0;
}

.cta_left {
    display: flex;
    align-items: center;
}

.cta_icon {
    width: 15%;
}

.cta_content {
    padding-left: 30px;
}

.cta_content h2 {
    padding-top: 11px;
}

/* --Footer Area-- */
#footer_area {
    padding: 100px 0px;
}

.footer_heading_area h5 {
    border-bottom: 1px solid var(--main-color);
    display: inline-flex;
    padding-bottom: 5px;
    font-weight: 500;
}

.footer_inquery_area {
    padding-top: 5px;
    border-left: 2px solid var(--main-color);
    margin-top: 25px;
    padding-left: 9px;
}

.footer_inquery_area h3 {
    font-weight: 500;
    /* padding-top: 8px; */
}

.footer_inquery_area h5 {
    font-size: 16px;
    font-weight: 500;
}

.soical_icon_footer {
    display: flex;
    padding-top: 5px;
}

.soical_icon_footer li {
    padding-right: 8px;
}

.footer_link_area {
    padding-top: 20px;
}

.footer_link_area ul li {
    padding-bottom: 15px;
}

.footer_link_area ul li a {
    color: var(--black-color);
    font-size: 16px;
    font-weight: 400;
    transition: var(--transition);
}

.footer_link_area ul li a:hover {
    color: var(--main-color);
}

.soical_icon_footer li a i {
    font-size: 16px;
}

.copyright_area {
    background-color: var(--bg-color);
    padding: 15px 0;
}

.copyright_area_two {
    background-color: var(--bg-color);
    padding: 15px 0;
    border-top: 1px solid #dfdfdf;
}

.copyright_left p {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 500;
}

.copyright_right {
    text-align: right;
}

/* ===================================
        Home Two Page
====================================*/
.banner_two_slider_item {
    padding: 325px 0 245px 0;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner_two_text h3 {
    color: var(--white-color);
    font-weight: 400;
}

.banner_two_text h1 {
    color: var(--white-color);
    font-size: 136px;
    padding-top: 35px;
    font-weight: 800;
    margin-inline-start: -5px;
}

.banner_two_text h2 {
    color: var(--white-color);
    padding-top: 30px;
}

.banner_two_text h4 {
    color: var(--white-color);
    padding-top: 10px;
    font-weight: 300;
}

.home_two_button {
    padding-top: 60px;
}

.home_two_button a {
    margin-right: 30px;
}

#home_two_banner .owl-item.active .banner_two_slider_item .banner_two_text .title {
    -webkit-animation: 1s 1.2s fadeInLeft both;
    animation: 1s 1.2s fadeInLeft both;
}

#home_two_banner .owl-item.active .banner_two_slider_item .banner_two_text .slider-sttle {
    -webkit-animation: 1s 1.1s bounceInLeft both;
    animation: 1s 1.1s bounceInLeft both;
}

#home_two_banner .owl-item.active .banner_two_slider_item .banner_two_text .slider-pararp {
    -webkit-animation: 1s 1.5s fadeInUp both;
    animation: 1s 1.5s fadeInUp both;
}

#home_two_banner .owl-item.active .banner_two_slider_item .banner_two_text .btn-animation {
    -webkit-animation: 1s 1.2s flipInX both;
    animation: 1s 1.2s flipInX both;
}

#home_two_banner .owl-theme .owl-nav {
    position: absolute;
    bottom: 45px;
    right: 60px;
}

#home_two_banner .owl-carousel .owl-nav button.owl-next,
#home_two_banner .owl-carousel .owl-nav button.owl-prev {
    background: 0 0;
    color: var(--white-color);
    border: 2px solid var(--white-color);
    font-size: 14px;
    height: 25px;
    width: 25px;
    line-height: 25px;
    border-radius: 0px;
    transition: var(--transition);
}

#home_two_banner .owl-carousel .owl-nav button.owl-next:hover,
#home_two_banner .owl-carousel .owl-nav button.owl-prev:hover {
    color: var(--main-color);
    border: 2px solid var(--main-color);
}

/* --Top Service Area-- */
.top_service_boxed {
    position: relative;
    background: var(--white-color);
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 12px;
    padding: 30px 35px;
    margin-top: 30px;
    transition: var(--transition);
    text-align: center;
}

.top_service_boxed:hover {
    transform: translateY(-10px);
}

.top_service_boxed img {
    transition: var(--transition);
}

.top_service_boxed:hover img {
    transform: translateY(5px);
}

.top_service_boxed h3 {
    padding-top: 35px;
    position: relative;
    padding-bottom: 10px;
    font-weight: 500;
}

.top_service_boxed h3::after {
    content: "";
    position: absolute;
    width: 160px;
    height: 1px;
    background: var(--main-color);
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
}

.top_service_boxed p {
    padding-top: 20px;
}

/* --Holiday Destinations Area-- */
.holiday_left_heading {
    background: #F3F6FD;
    border-radius: 20px;
    padding: 70px 45px;
    margin-top: 30px;
}

.holiday_small_boxed {
    margin-top: 30px;
    border-radius: 17px;
    overflow: hidden;
    position: relative;
}

.holiday_small_boxed img {
    width: 100%;
    transition: var(--transition);
}

.holiday_small_boxed:hover img {
    transform: scale(1.1);
}

.holiday_small_boxed:hover .holiday_small_box_content {
    opacity: 1;
}

.holiday_small_box_content {
    background: linear-gradient(359deg, #111018 0%, rgb(217 217 217 / 0%) 70%);
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
}

.holiday_inner_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
}

.rating_outof {
    display: flex;
    align-items: center;
}

.rating_outof i {
    color: var(--white-color);
    font-size: 14px;
}

.rating_outof h5 {
    color: var(--white-color);
    padding-left: 10px;
    font-weight: 300;
}

.holiday_inner_content h3 {
    color: var(--white-color);
    padding: 10px 0;
    font-weight: 400;
}

.holiday_inner_content h4 {
    color: var(--white-color);
    font-weight: 400;
}

.holiday_inner_content p {
    color: var(--white-color);
    padding-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

/* --About Area-- */
.about_two_left_top h5 {
    color: var(--main-color);
    font-weight: 500;
}

.about_two_left_top h2 {
    padding-top: 17px;
}

.about_two_left_top p {
    padding-top: 15px;
}

.about_two_item {
    display: flex;
    padding-top: 40px;
    transition: var(--transition);
}

.about_two_item:hover {
    transform: translateY(-10px);
}

.about_two_item_icon {
    width: 14%;
}

.about_two_item_text {
    padding-left: 10px;
}

.about_two_item_text h3 {
    font-weight: 500;
}

.about_two_item_text p {
    padding-top: 6px;
}

/* --Partner Aarea-- */
.partner_area_bg {
    /* background-image: url('../img/common/video_bg.png'); */
    padding: 70px 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.partner_area_left {
    padding-left: 115px;
}

.partner_area_left h5 {
    color: var(--main-color);
    font-weight: 500;
}

.partner_area_left h2 {
    padding-top: 15px;
    line-height: 45px;
}

.partner_area_left p {
    padding-top: 10px;
}

.partner_video_area {
    padding-top: 35px;
    padding-left: 30px;
}

.video_play_area i {
    color: var(--white-color);
    position: relative;
    z-index: 99;
    font-size: 18px;
}


.video_play_area:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--main-color);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.video_play_area:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--main-color);
    border-radius: 50%;
    transition: all 200ms;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
        opacity: 0;
    }
}

.local_counter_area {
    padding-top: 40px;
    display: flex;
}

.local_counter_box {
    background: var(--white-color);
    padding: 10px 25px;
    border-radius: 12px;
    margin-right: 15px;
}

.local_counter_box h2 {
    padding-top: 0;
    font-weight: 700;
}

.local_counter_box h5 {
    padding-top: 4px;
    font-weight: 500;
}

/* --Choose your destinations-- */
.choose_des_inner_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.choose_desti_wrapper:hover .choose_des_inner_wrap {
    transform: rotateY(180deg);
}

.choose_boxed_inner,
.flep_choose_box {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
}

.flep_choose_box {
    background-color: var(--main-color);
    transform: rotateY(180deg);
    border-radius: 12px;
}

.choose_boxed_inner {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.choose_boxed_inner img {
    width: 100%;
}

.choose_img_text {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.choose_img_text h2 {
    color: var(--white-color);
}

.choose_img_text h3 {
    color: var(--white-color);
    padding-top: 10px;
    font-weight: 400;
}

.flep_choose_box_inner {
    padding: 65px 25px 0px 35px;
}

.flep_choose_box_inner h2 {
    color: var(--white-color);
    padding-top: 15px;
}

.flep_choose_box_inner h3 {
    color: var(--white-color);
    padding-top: 12px;
    font-weight: 500;
}

.flep_choose_box_inner p {
    color: #ffffffd1;
    padding-top: 14px;
    font-weight: 300;
    line-height: 24px;
}

.flep_choose_box_inner a {
    color: var(--white-color);
    margin-top: 25px;
    border-bottom: 1px solid var(--white-color);
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
}

.choose_desti_wrapper {
    margin-top: 30px;
}

/* --Client Area--*/

.client_two_area_bg {
    /* background-image: url('../img/banner/client-bg.png'); */
    padding: 15px 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.two_client_area_left {
    padding-left: 115px;
}

.two_client_area_left h2 {
    color: var(--white-color);
    line-height: 56px;
}

.sm_call_area {
    display: flex;
    align-items: center;
    padding-top: 30px;
}

.sm_call_number {
    padding-left: 15px;
}

.sm_call_number h5 {
    color: var(--white-color);
}

.sm_call_number h2 a {
    color: var(--white-color);
}

.sm_call_number h2 a:hover {
    color: var(--main-color);
}

.home_client_wrpper {
    background: var(--white-color);
    box-shadow: 8px 12px 16px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    text-align: center;
    padding: 55px 0;
    position: relative;
    top: 122px;
}

.home_client_wrpper h4 {
    color: var(--paragraph-color);
    font-weight: 400;
}

.home_client_wrpper h2 {
    font-weight: 400;
    padding-top: 15px;
}

.home_client_wrpper h3 {
    padding-top: 35px;
    font-weight: 500;
    color: var(--main-color);
}

.client_two_content {
    padding-top: 25px;
}

.home_two_client_slider {
    padding: 0 65px;
}

.client_two_content p {
    font-size: 18px;
    color: var(--black-color);
    font-weight: 400;
    line-height: 36px;
}

.client_two_author {
    padding-top: 15px;
}

.client_two_author h5 {
    font-weight: 500;
}

.client_two_author h6 {
    padding-top: 5px;
    color: var(--main-color);
    font-weight: 600;
}

.home-two-quote {
    position: relative;
    display: inline-block;
}

.home-two-quote img {
    position: absolute;
    top: 15px;
    left: -22px;
}

#hometwo_clinet_area .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 15px;
}

#hometwo_clinet_area .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #2b2540b8;
    display: block;
    transition: opacity .2s ease;
    border-radius: 30px;
}

#hometwo_clinet_area .owl-theme .owl-dots .owl-dot.active span,
#hometwo_clinet_area .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--main-color);
}

/* =============================
        Home Page Three
================================*/
#home_three_banner {
    /* background-image: url(../img/banner/banner-three.png); */
    padding: 325px 0 340px 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner_area_three {
    text-align: center;
}

.banner_area_three h2 {
    font-size: 80px;
    line-height: 90px;
    color: var(--white-color);
}

.banner_area_three h3 {
    color: var(--white-color);
    font-weight: 400;
}

.top_banner_three_text {
    padding-bottom: 20px;
}

.bottom_banner_three_text {
    padding-top: 23px;
}

/* --About Area-- */
.three_about_left {
    position: relative;
}

.three_about_left a img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.verified_area {
    position: absolute;
    right: -105px;
    bottom: 0;
}

.three_heading_area {
    color: var(--main-color);
    font-weight: 400;
    position: relative;
}

.three_heading_area::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 1px;
    left: 145px;
    top: 13px;
    background-color: var(--main-color);
}

.three_about_right h4 {
    padding-bottom: 10px;
}

.three_about_right h2 {
    line-height: 51px;
}

.three_about_right p {
    padding-top: 15px;
}

.about_three_flex {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}

.about_counter_item {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    text-align: center;
    padding: 10px 35px;
}

.about_counter_item h2 {
    line-height: 40px;
}

.about_counter_item p {
    padding-top: 0;
    color: var(--main-color);
    font-size: 18px;
    font-weight: 500;
}

/* --We Offer-- */
#three_We_area {
    /* background-image: url('../img/common/bg.png'); */
}

.we_offer_area_three h2 {
    padding-top: 15px;
}

.we_offer_area_three p {
    padding-top: 20px;
}

.three_offer_item {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.4);
    box-shadow: 4px 8px 14px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    text-align: center;
    padding: 30px 20px;
}

.three_offer_item img {
    width: auto !important;
    margin: 0 auto;
}

.three_offer_item h3 {
    padding-top: 15px;
    font-weight: 500;
}

.three_offer_item p {
    padding-top: 10px;
}

.three_offer_slider {
    padding-top: 20px;
}

#three_We_area .owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: -50px;
}

#three_We_area .owl-carousel .owl-nav button.owl-next,
#three_We_area .owl-carousel .owl-nav button.owl-prev {
    background: var(--black-color);
    color: var(--white-color);
    border: none;
    padding: 0 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

#three_We_area .owl-carousel .owl-nav button.owl-next:hover,
#three_We_area .owl-carousel .owl-nav button.owl-prev:hover {
    background: var(--main-color);
    color: var(--white-color);
}

/* --Trending Tour Area-- */
.arrow_style.owl-theme .owl-nav {
    margin-top: 0px;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.arrow_style.owl-carousel .owl-nav button.owl-next,
.arrow_style.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    background: var(--black-color);
    color: var(--white-color);
    border: none;
    padding: 0 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.arrow_style.owl-carousel .owl-nav button.owl-next:hover,
.arrow_style.owl-carousel .owl-nav button.owl-prev:hover {
    background: var(--main-color);
    color: var(--white-color);
}

.arrow_style.owl-carousel .owl-nav button.owl-next {
    right: -25px;
}

.arrow_style.owl-carousel .owl-nav button.owl-prev {
    left: -25px;
}

/* --Width offer area-- */
#width_offer_area {
    background: #F3F6FD;
    padding: 70px 0;
}

.width_offer_item {
    display: flex;
}

.width_offer_text {
    width: 80%;
    padding-left: 25px;
}

.width_offer_text h3 {
    font-weight: 500;
    padding-bottom: 10px;
}

/* --Client Review-- */
#home_Three_client .home_client_wrpper {
    background: #F3F6FD;
    box-shadow: 0px 5px 8px 3px #00000014;
    border-radius: 12px;
    text-align: center;
    padding: 55px 0;
    position: relative;
    top: 0;
}

.list-items-3 li {
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flex;
    display: flex;
    justify-content: start;
    margin: .6em;
    word-break: break-word;
}
.list-items-3 li span {
    width: 50%;
}

.invoice-heading {
    background: aliceblue;
    padding: .5em;
}

/* ===================================
    Home Page four
====================================*/
/* --Banner Area-- */
.navbar_color_black .main-navbar .navbar .navbar-nav .nav-item a {
    color: var(--black-color);
    font-weight: 400;
}

.navbar_color_black .main-navbar .navbar .others-options .option-item .btn:hover {
    color: var(--black-color);
}

.navbar_color_black .main-navbar .navbar .others-options .option-item a i {
    color: var(--black-color);
}

.navbar_color_black .main-navbar .navbar .others-options .option-item a i:hover {
    color: var(--black-color);
}

.navbar_color_black .topbar-list li a {
    color: var(--black-color);
}

.navbar_color_black .topbar-list li i {
    color: var(--black-color);
}

.navbar_color_black .topbar-others-options li a {
    color: var(--black-color);
}

.navbar_color_black .topbar-others-options li .language-option button {
    color: var(--black-color);
}

.navbar_color_black .topbar-area {
    border-bottom: 1px solid #63616121;
}


#home_four_banner {
    background-color: #f4f6fc;
    padding: 220px 0;
}

.banner_four_img {
    position: absolute;
    top: 0;
    right: 0;
}

.banner_four_img img {
    width: 100%;
}

.banner_four_text h1 {
    font-size: 90px;
    font-weight: 500;
    padding-top: 20px;
    line-height: 105px;
}

.banner_four_text h3 {
    padding-top: 25px;
    color: #1F1E46;
    font-weight: 500;
}

.banner_four_text h4 {
    color: #6D6D74;
    font-size: 24px;
    font-weight: 600;
}

.banner_four_text {
    padding-right: 100px;
}

/* --Banner Bottom Area-- */
.banner_bootom_four {
    padding-top: 200px;
}

.four_banner_bottom_item {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    transition: var(--transition);
    border-radius: 12px;
    text-align: center;
    padding: 30px 20px;
}

.four_banner_bottom_item:hover {
    box-shadow: 4px 8px 16px rgba(28, 27, 35, 0.06);
    transform: translateY(-10px);
}

.four_banner_bottom_item h3 {
    padding-top: 20px;
    font-weight: 500;
}

.four_banner_bottom_item p {
    padding-top: 15px;
}


/* --Popular Tours-- */
.section_heading_left {
    padding-bottom: 30px;
}

.section_heading_left h2 {
    padding-bottom: 8px;
}

.common_card_four {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
}

.common_card_four_img {
    overflow: hidden;
}

.common_card_four_img a img {
    width: 100%;
    transition: var(--transition);
}

.common_card_four:hover .common_card_four_img a img {
    transform: scale(1.1);
}

.common_card_four_text {
    padding: 20px 10px;
}

.common_card_four_list li {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #727178;
    align-items: center;
}

.common_card_four_list li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px;
}

.common_card_four_text h3 {
    padding-top: 5px;
}

.common_card_four_text h3 a {
    font-size: 20px;
    color: var(--black-color);
    font-weight: 400;
}

.common_card_four:hover .common_card_four_text h3 a {
    color: var(--main-color);
}

.common_card_four_text p {
    font-size: 14px;
    padding-top: 5px;
}

.common_card_four_text p i {
    font-size: 14px;
    padding-right: 5px;
}

.common_card_four_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 12px;
}

.common_card_four_bottom_left p {
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
}

.common_card_four_bottom_left h6 {
    color: #727178;
    font-size: 12px;
    padding-top: 3px;
}

.common_card_four_bottom_right h4 sub {
    font-size: 12px;
}

.button_style_top_left.owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: -90px;
}

.button_style_top_left.owl-carousel .owl-nav button.owl-next,
.button_style_top_left.owl-carousel .owl-nav button.owl-prev {
    background: var(--black-color);
    color: var(--white-color);
    border: none;
    padding: 0 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.button_style_top_left.owl-carousel .owl-nav button.owl-next:hover,
.button_style_top_left.owl-carousel .owl-nav button.owl-prev:hover {
    background: var(--main-color);
    color: var(--white-color);
}

/* --Beest Offer-- */
.theme_nav_tab_four_item .nav-tabs {
    border-bottom: none;
    justify-content: flex-end;
}

.theme_nav_tab_four_item .nav-tabs .nav-link {
    background: #FBF9FE;
    margin-left: 17px;
    border: 1px solid #DDDDDD;
    border-radius: 7px;
    color: #2B2540;
    font-size: 16px;
    font-weight: 500;
}

.theme_nav_tab_four_item .nav-tabs .nav-link.active {
    background-color: var(--main-color);
    color: var(--white-color);
}

/* --Tour Type Boxed-- */
.tour_type_boxed {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 8px;
    text-align: center;
    padding: 20px 20px;
    transition: var(--transition);
}

.tour_type_boxed:hover {
    transform: translateY(-10px);
}

.tour_type_boxed img {
    width: auto !important;
    margin: 0 auto;
}

.tour_type_boxed h3 {
    padding-top: 10px;
    font-size: 20px;
    font-weight: 500;
}

.tour_type_boxed p {
    padding-top: 5px;
}

/* --Destination for your-- */
.section_heading_left_view_all {
    text-align: right;
    padding-top: 45px;
}

.section_heading_left_view_all a {
    color: var(--main-color);
    font-size: 18px;
    font-weight: 500;
}

.section_heading_left_view_all a:hover {
    color: var(--black-color);
}

/* --Top Details -- */
.top_details_four_item {
    position: relative;
}

.top_details_four_item img {
    width: 100%;
}

.top_details_posation {
    position: absolute;
    bottom: 34px;
    left: 25px;
}

.top_details_posation p {
    color: var(--white-color);
}

.top_details_posation h3 {
    font-size: 20px;
    color: var(--white-color);
    font-weight: 500;
    padding-bottom: 22px;
}

/* --Counter Area-- */
.countre_four_item {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 12px;
    text-align: center;
    padding: 15px 20px;
}

.countre_four_item h3 {
    font-weight: 600;
    padding-bottom: 3px;
}

.countre_four_item h5 {
    font-size: 16px;
    color: var(--main-color);
    padding-top: 2px;
    font-weight: 500;
}

/* --Testimonials Area-- */
#testimonial_four_area {
    /* background-image: url('../img/common/test-bg.png'); */
    padding: 130px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.test_slider_item_four {
    text-align: center;
}

.test_post_img_four img {
    width: auto !important;
    margin: 0 auto;
}

.test_four_author_fill {
    padding-top: 30px;
    position: relative;
    display: inline-grid;
}

.test_four_author_fill img {
    position: absolute;
    left: 0;
    bottom: 8px;
    width: auto !important;
}

.test_four_author_fill h3 {
    font-weight: 500;
    color: var(--main-color);
}

.test_four_inner_content {
    padding-top: 18px;
}

.test_four_author_name {
    padding-top: 12px;
    padding-bottom: 15px;
}

.test_four_author_name h5 {
    font-weight: 600;
    font-size: 16px;
}

.test_four_author_name p {
    font-size: 16px;
}

/* --Our Partner-- */
#our_partners_four {
    background-color: #F3F6FD;
}

#our_partners_four .owl-carousel .owl-item img {
    width: auto !important;
    filter: opacity(0.5);
}

#our_partners_four .owl-carousel .owl-item img:hover {
    filter: opacity(1);
}

/* --News Area-- */
.news_card_four_heading ul li {
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    color: #727178;
    align-items: center;
}

.news_card_four_heading ul li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px;
}

.news_card_four_img {
    overflow: hidden;
    border-radius: 20px;
}

.news_card_four_img a img {
    width: 100%;
    transition: var(--transition);
}

.news_card_four_wrapper:hover .news_card_four_img a img {
    transform: scale(1.1);
}

.news_card_four_heading {
    padding-top: 20px;
}

.news_card_four_heading h3 {
    font-weight: 600;
    padding-bottom: 10px;
}

.news_card_four_heading h3 a {
    color: var(--black-color);
}

.news_card_four_wrapper:hover .news_card_four_heading h3 a {
    color: var(--main-color);
}

.navbar_color_black .is-sticky .main-navbar .navbar .navbar-nav .nav-item a {
    color: var(--white-color);
}

.navbar_color_black .is-sticky .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a {
    color: var(--navber-color) !important;
}

.navbar_color_black .is-sticky .main-navbar .navbar .others-options .option-item a i {
    color: var(--white-color);
}

/* ==========================
        News v4 Page
============================*/
.news_four_card {
    margin-bottom: 30px;
}

.new_four_card_lists {
    padding-top: 15px;
}

.new_four_card_lists ul li {
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    color: #727178;
    align-items: center;
}

.new_four_card_lists ul li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px;
}

.new_four_card_lists ul li span {
    color: var(--main-color);
}

.news_four_card_conrent h3 {
    padding-top: 12px;
    font-size: 24px;
    font-weight: 500;
}

.news_four_card_conrent h3 a {
    color: var(--black-color);
}

.news_four_card_conrent h3 a:hover {
    color: var(--main-color);
}

.news_four_card_conrent p {
    padding-top: 8px;
    padding-bottom: 10px;
}

/* ==========================
    News Details v2 Page
============================*/
.news_details_content_area img {
    width: 100%;
}

.news_details_four_heading {
    background: #FFFFFF;
    box-shadow: 4px 10px 20px rgb(0 0 0 / 8%);
    border-radius: 12px;
    padding: 20px 20px;
}

.news_details_four_heading h2 {
    padding-top: 10px !important;
}

.news_details_content_tops {
    margin-top: -95px;
}

/* =========================
    Contact v2 Page
=========================*/
.contact_left_top_heading h3 {
    padding-top: 35px;
    font-weight: 500;
}

.contact_left_top_heading p {
    padding-top: 15px;
}

.contact_form_two {
    padding-top: 40px;
}

.contact_two_left_wrapper {
    padding-top: 110px;
}

.contact_two_left_wrapper h3 {
    font-weight: 500;
}

.contact_details_wrapper {
    padding-top: 14px;
}

.contact_detais_item {
    padding-bottom: 25px;
}

.contact_detais_item h4 {
    font-size: 16px;
    color: #727178;
    font-weight: 500;
    padding-bottom: 8px;
}

.contact_detais_item h3 {
    color: var(--main-color) !important;
}

.contact_map_area iframe {
    width: 100%;
    height: 200px;
}


/* =========================
    Become Expert Page
=========================*/
.how_expert_boxed {
    text-align: center;
    padding-top: 30px;
}

.how_expert_boxed h3 {
    padding-top: 20px;
    font-weight: 500;
}

.how_expert_boxed p {
    padding-top: 10px;
}

.video_two_left_side {
    background: #F3F6FD;
    border-radius: 12px;
    padding: 63px 40px;
}

.video_two_left_side h2 {
    padding-bottom: 10px;
}

.video_exp_item {
    display: flex;
    align-items: center;
}

.video_exp_icon {
    width: 14%;
}

.video_exp_icon img {
    width: 90%;
}

.video_exp_text {
    padding-top: 25px;
}

.video_exp_text h3 {
    font-weight: 500;
    padding-bottom: 9px;
}

.video_two_right_side {
    position: relative;
}

.video_two_play_area {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* =========================
     Cruise Search Page
=========================*/
.item_searc_map_area iframe {
    width: 100%;
    height: 200px;
}

.name_search_form {
    margin-top: 20px;
    position: relative;
}

.name_search_form i {
    position: absolute;
    right: 15px;
    top: 20px;
}

.cruise_search_item {
    margin-bottom: 30px;
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
}

.cruise_item_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cruise_item_img img {
    width: 100%;
    height: 22em;
    object-fit: cover;
}

.cruise_item_inner_content {
    padding: 15px 20px 22px 0px;
}

.cruise_content_top_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cruise_content_top_left ul li {
    display: inline-flex;
    font-size: 12px;
    font-weight: 500;
    color: #727178;
    align-items: center;
}

.cruise_content_top_left ul li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px
}

.cruise_content_top_left h4 {
    padding-top: 8px;
}
.cursor-pointer{
    cursor: pointer;
}
.home-adult-modal {
    display: flex;
    flex-wrap: wrap;
}
.cruise_content_top_left p {
    padding-top: 8px;
}

.cruise_content_top_right h5 {
    font-size: 16px;
    color: var(--main-color);
    font-weight: 500;
}

.cruise_content_top_right h4 {
    font-size: 16px;
    padding-top: 5px;
}

.cruise_content_middel_wrapper {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
}

.cruise_content_middel_left h5 {
    font-size: 16px;
    color: var(--main-color);
    font-weight: 500;
    padding-bottom: 4px;
}

.cruise_content_middel_right h3 {
    font-weight: 500;
}

.cruise_content_middel_right h3 sub {
    font-size: 14px;
}

.cruise_content_middel_right p {
    font-size: 14px;
    padding-top: 3px;
}

.cruise_content_bottom_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 23px;
}

.cruise_content_bottom_left ul li {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    display: inline-block;
    padding: 3px 14px;
    font-size: 14px;
    color: var(--black-color);
    margin-right: 10px;
}


/* =========================
     Cruise Map Page
=========================*/
.side_map_wrapper iframe {
    width: 100%;
    height: 900px;
}

/* =========================
   Bus Booking Page
=========================*/
.btn_modal_bus {
    z-index: 9999;
}

.bus_modal_heading {
    padding-top: 27px;
}

.bus_modal_heading h3 {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 6px;
}

.bus_seat_left_side {
    padding-top: 20px;
}

.bus_seat_left_side h6 {
    font-size: 12px;
    padding-bottom: 5px;
    line-height: 22px;
}

.bus_seat_right_side {
    padding-top: 20px;
}

.seat_avelabel_item {
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    justify-content: center;
}

.seat_avelabel_item h4 {
    font-size: 14px;
    padding-left: 10px;
}

.bus_ticket_tabel table {
    width: 100%;
}

.bus_ticket_tabel table thead tr {
    background: #F3EFF9;
    text-align: center;
    border: 1px solid #e7e7e7;
}

.bus_ticket_tabel table thead tr th {
    padding: 10px 15px;
}

.bus_ticket_tabel table tbody tr {
    text-align: center;
    border: 1px solid #e7e7e7;
}

.bus_ticket_tabel table tbody tr td {
    padding: 10px 15px;
}

.bus_price_total {
    padding-top: 25px;
}

.bus_price_total h5 {
    background-color: #F3EFF9;
    padding: 8px 15px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
}

.bus_price_total h5 span {
    color: var(--main-color);
}

.bus_ticket_continue {
    text-align: right;
    padding-top: 20px;
}


/* =========================
       Visa details Page
=========================*/

.visa_holder_area .tour_details_boxed_inner h3 {
    font-weight: 600;
    padding-bottom: 20px;
    font-size: 18px;
}

.visa_holder_area .tour_details_boxed_inner {
    padding-top: 8px;
}

/* =========================
    Visa information Page
=========================*/
.visa_info_card_sm {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.08), 4px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    text-align: center;
    padding: 25px 11px;
    margin-top: 45px;
}

.visa_info_card_sm h3 {
    padding-top: 12px !important;
}

.visa_info_card_sm p {
    padding-top: 10px !important;
}

.area_visa_info_add {
    padding-top: 30px;
}

.visa_category_item ul li {
    padding-top: 18px;
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 18px;
}

.visa_category_item ul li:first-child {
    padding-top: 0;
}

.visa_category_item ul li:last-child {
    padding-bottom: 0;
    border: none;
}

.visa_category_item ul li a {
    font-size: 18px;
    font-weight: 600;
    color: var(--black-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visa_category_item ul li a:hover {
    color: var(--main-color);
}

.visa_info_side_call {
    background-color: var(--main-color);
    text-align: center;
    color: var(--white-color);
    padding: 30px 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.visa_info_side_call h2 {
    padding-top: 15px;
}

.visa_info_side_call h2 a {
    color: var(--white-color);
}

.visa_info_side_call h2 a:hover {
    color: var(--black-color);
}

.visa_info_side_call h2 i {
    padding-right: 15px;
}

.visa_info_sid_padding_none {
    padding: 0 !important;
    overflow: hidden;
    position: relative;
}

.download_pdf_area_visa {
    display: flex;
    align-items: center;
    background: #EEF4F8;
    padding: 30px 20px;
}

.downloads_pdf_text_visa {
    padding-left: 14px;
}

.downloads_pdf_text_visa a h3 {
    border-bottom: none;
    padding-bottom: 0;
    color: var(--black-color);
}

.downloads_pdf_text_visa a h5 {
    color: var(--paragraph-color);
    padding-top: 10px;
    font-size: 16px;
}

.download_pdf_area_visa:hover .downloads_pdf_text_visa a h3 {
    color: var(--main-color);
}

.visa_request_side_form form .form-group {
    padding-top: 30px;
}

.visa_request_side_form_button {
    padding-top: 30px;
}

.visa_info_side_middle img {
    width: 100%;
}

/* =========================
       Tour Search Page
=========================*/
/* --Common Banner-- */
#common_banner {
    /* background-image: url('../img/banner/common-banner.png'); */
    padding: 200px 0 130px 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.common_bannner_text {
    text-align: center;
}

.common_bannner_text h2 {
    color: var(--white-color);
    font-weight: 400;
}

.common_bannner_text ul {
    padding-top: 20px;
}

.common_bannner_text ul li {
    display: inline-block;
    color: var(--white-color);
    padding-left: 7px;
}

.common_bannner_text ul li:first-child {
    padding-left: 0px;
}

.common_bannner_text ul li span {
    padding-right: 5px;
}

.common_bannner_text ul li span i {
    color: var(--white-color);
    font-size: 7px;
    position: relative;
    top: -2px;
    left: -3px;
}

.common_bannner_text ul li a {
    color: #ffffff91;
}

.common_bannner_text ul li a {
    color: #ffffff91;
}

.common_bannner_text ul li a:hover {
    color: var(--main-color);
}

/* --Search Form-- */
#theme_search_form_tour {
    margin-top: -85px
}

.pagination_area .page-link {
    color: var(--main-color);
    border-radius: 5px;
    padding: 2px 8px;
    font-size: 14px;
}

.pagination_area .page-link:hover {
    z-index: 2;
    color: var(--white-color);
    background-color: var(--main-color);
    border-color: var(--main-color);
}

.pagination_area .page-link:focus {
    z-index: 2;
    color: var(--white-color);
    background-color: var(--main-color);
    outline: none;
    box-shadow: none;
}

.pagination_area .page-item {
    margin: 0 6px;
}

.pagination_area .pagination {
    justify-content: center;
    padding-top: 30px;
}

.left_side_search_boxed {
    background: #FFFFFF;
    border: 1px solid #00000030;
    border-radius: 6px;
    padding: 20px 15px;
    margin-bottom: 30px;
}

.left_side_search_heading h5 {
    border-bottom: 1px solid #00000030;
    padding-bottom: 7px;
    font-size: 16px;
    font-weight: 500;
}

.filter-price {
    padding-top: 30px;
    padding-bottom: 50px;
}

.apply {
    border: none;
    background: transparent;
    padding: 0;
    color: var(--main-color);
    font-weight: 500;
}

.filter-price-text {
    padding-bottom: 20px;
}

.noUi-horizontal .noUi-tooltip {
    margin-top: 13px;
    font-size: 15px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle.noUi-handle-upper {
    right: -4px;
    top: 4px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -13px;
    top: 4px;
}

.noUi-horizontal .noUi-handle {
    width: 15px;
    height: 15px;
}

.noUi-connect {
    background: var(--main-color);
}

.review_star {
    padding-top: 10px;
}

.review_star .form-check {
    margin-top: 15px;
    margin-bottom: 0;
}

.color_theme {
    color: var(--main-color);
}

.color_asse {
    color: #DDDDDD;
}

.form-check-label {
    width: 100%;
}

.tour_search_type {
    padding-top: 10px;
}

.tour_search_type .form-check {
    padding-top: 15px;
}

.area_flex_one {
    display: flex;
    justify-content: space-between;
}

.area_flex_one span {
    color: var(--black-color);
    font-weight: 500;
}

.tour_details_right_boxed {
    background: #FFFFFF;
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 25px 20px 35px 20px;
}

.first_child_padding_none ul li:first-child {
    padding-top: 0;
}

.tour_details_right_box_heading h3 {
    font-weight: 500;
    font-size: 22px;
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 10px;
    display: inline-block;
}

.valid_date_area {
    display: flex;
    align-items: center;
    padding-top: 25px;
}

.valid_date_area_one {
    padding-right: 40px;
}

.valid_date_area_one h5 {
    font-weight: 500;
    padding-bottom: 5px;
}

.tour_package_details_bar_list {
    padding-top: 20px;
}

.tour_package_details_bar_list h5 {
    font-weight: 500;
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 10px;
    display: inline-block;
}

.tour_package_details_bar_list ul li {
    padding-top: 15px;
    color: var(--paragraph-color);
    display: flex;
    align-items: center;
}

.tour_package_details_bar_list ul li i {
    color: var(--black-color);
    font-size: 6px;
    padding-right: 7px;
}

.tour_package_details_bar_price {
    padding-top: 20px;
}

.tour_package_details_bar_price h5 {
    font-weight: 500;
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 10px;
    display: inline-block;
}

.tour_package_bar_price {
    display: flex;
    align-items: center;
    padding-top: 15px;
}

.tour_package_bar_price h6 {
    font-size: 16px;
    font-weight: 500;
}

.tour_package_bar_price h3 {
    padding-left: 10px;
    font-size: 22px;
    font-weight: 500;
    color: var(--main-color);
}

.tour_package_bar_price h3 sub {
    color: var(--paragraph-color);
    font-weight: 400;
    bottom: 0;
    font-size: 14px;
}

.tour_select_offer_bar_bottom button {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.select_offer_modal.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;
    z-index: 999999;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.travel_date_side .form-control {
    border: 1px solid var(--black-color);
    margin-top: 10px;
}

.select_person_side {
    padding-top: 25px;
}

.select_person_side h3 {
    font-size: 18px;
    font-weight: 500;
}

.select_person_item {
    padding-top: 20px;
    border-bottom: 1px solid #00000030;
    padding-bottom: 7px;
    
   
    align-items: center;
}

.select_person_left h6 {
    font-size: 16px;
    font-weight: 500;
}

.select_person_left p {
    font-size: 12px;
    font-weight: 500;
}

.select_person_right button {
    border: 1px solid #d6d6d6;
    background: transparent;
    font-size: 10px;
    transition: var(--transition);
}

.select_person_right button:hover {
    background: var(--main-color);
    color: var(--white-color);
}

.select_person_right span {
    font-size: 14px;
    padding: 0 4px;
}

.write_spical_not {
    padding-top: 30px;
}


.write_spical_not textarea {
    height: 100%;
    border: 1px solid var(--black-color);
    margin-top: 10px;
}

.write_spical_check {
    padding-top: 10px;
}

.main_spical_check p {
    font-size: 14px;
}

.main_spical_check p span {
    color: var(--main-color);
}

.proceed_booking_btn a {
    border-radius: 0;
}

.tour_details_heading_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tour_details_top_heading h5 {
    padding-top: 15px;
    font-size: 16px;
}

.tour_details_top_heading_right h4 {
    color: var(--main-color);
}

.tour_details_top_heading_right h6 {
    font-size: 16px;
    color: var(--main-color);
    padding-top: 5px;
}

.tour_details_top_heading_right p {
    padding-top: 2px;
    font-size: 14px;
}

.tour_details_top_bottom {
    margin-top: 25px;
    border-top: 1px solid #eeeaea;
    padding-top: 13px;
    border-bottom: 1px solid #eeeaea;
    padding-bottom: 13px;
    display: flex;
    justify-content: space-between;
}

.toru_details_top_bottom_item {
    display: flex;
    align-items: center;
}

.tour_details_top_bottom_icon {
    font-size: 30px;
    padding-right: 10px;
}

.tour_details_top_bottom_text h5 {
    font-weight: 500;
}

.tour_details_top_bottom_text p {
    font-size: 14px;
}

.tour_details_img_wrapper {
    margin-top: 40px;
    display: block;
}

.tour_details_img_wrapper .slider-nav {
    margin-top: 20px;
}

.tour_details_boxed {
    background: #FFFFFF;
    box-shadow: -4px -5px 14px rgb(0 0 0 / 8%), 5px 8px 16px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 20px 20px;
    /* margin-top: 30px; */
}
.lead-passenger-detail p{
font-size: 14px;
}
.heading_theme {
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 10px;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;
}

.tour_details_boxed_inner p {
    padding-bottom: 15px;
}

.tour_details_boxed_inner ul li {
   
    color: var(--paragraph-color);
    display: flex;
    justify-content: space-between;
}

.tour_details_boxed_inner ul li i {
    color: var(--black-color);
    font-size: 6px;
    padding-right: 7px;
    padding-top: 6px;
}

.tour_details_boxed_inner .accordion-button {
    padding: 0;
    font-weight: 600;
    font-size: 18px;
}

.tour_details_boxed_inner .accordion-item {
    border: none;
    border-radius: 0;
    padding-bottom: 15px;
    width: 100%;
}

.tour_details_boxed_inner .accordion-button:not(.collapsed) {
    color: var(--main-color);
    background-color: #fff;
    box-shadow: none;
}

.tour_details_boxed_inner .accordion-button:focus {
    z-index: 3;
    border-color: #fff;
    outline: 0;
    box-shadow: none;
}

.tour_details_boxed_inner .accordion-body {
    padding: 0;
}

.accordion_itinerary_list {
    padding-top: 15px;
}

.accordion_flex_area {
    display: flex;
}

.accordion_left_side h5 {
    width: 100px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background: #4c82ff24;
    margin-right: 15px;
    font-weight: 600;
    font-size: 18px;
}

.map_area {
    width: 100%;
}

.map_area iframe {
    width: 100%;
    height: 300px;
}

.tour_detail_right_sidebar {
    margin-bottom: 30px;
}

.write_your_review_wrapper {
    background: #FFFFFF;
    box-shadow: -4px -5px 14px rgb(0 0 0 / 8%), 5px 8px 16px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 20px 20px;
    margin-top: 50px;
}

.write_review_inner_boxed {
    text-align: center;
    padding-top: 20px;
}

.all_review_wrapper {
    margin-top: 60px;
}

.all_review_box {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.08), 4px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 20px 20px;
    position: relative;
    margin-top: 65px;
}

.all_review_date_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all_review_date h5 {
    font-size: 16px;
}

.all_review_star {
    text-align: center;
}

.all_review_star h5 {
    font-size: 16px;
}

.review_star_all i {
    font-size: 14px;
    color: var(--main-color);
}

.all_review_text {
    text-align: center;
    margin-top: -100px;
}

.all_review_text img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.all_review_text span {
    padding-top: 5px;
    display: block;
}

.all_review_text h4 {
    padding-top: 20px;
}

.all_review_text p {
    padding-top: 15px;
}

.all_review_small_img {
    display: flex;
    align-items: center;
    padding-top: 30px;
}

.all_review_small_img_item {
    padding-right: 11px;
}

.all_review_small_img .all_review_small_img_item:last-child {
    padding-right: 0px;
}

.all_review_small_img_item h5 {
    color: var(--main-color);
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
}

/* =========================
    Top Destinations Page
=========================*/
.payment_toggle {
    display: none;
}

#payment_checked .form-check-label {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 13px;
    cursor: pointer;
}

.payment_card,
.paypal_payment,
.payoneer_payment,
.cash_payment {
    padding-top: 15px;
}

.payment_card .form-group {
    margin-bottom: 30px;
    cursor: pointer;
}

#payment_checked .form-check:last-child .form-check-label {
    margin-bottom: 0;
}

.top_destinations_box {
    position: relative;
    margin-bottom: 30px;
    border-radius: 12px;
}

.top_destinations_box_content {
    position: absolute;
    bottom: 0px;
    left: 0;
    padding: 20px 20px;
    width: 100%;
}

.top_destinations_box_content h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 22px;
}

.top_destinations_box_content h4 a {
    color: var(--white-color);
}

.top_destinations_box_content h4 a:hover {
    color: var(--main-color);
}

.top_destinations_box_content p {
    padding: 6px 0px;
    font-size: 14px;
    color: var(--white-color);
}

.top_destinations_box_content p .review_rating {
    color: var(--white-color);
}

.top_destinations_box_content p .review_count {
    margin-left: 10px;
}

.top_destinations_box_content h3 {
    font-weight: 500;
    color: var(--white-color);
}

.top_destinations_box_content h3 span {
    font-weight: 400;
    font-size: 14px;
}

.heart_destinations {
    right: 17px;
    position: absolute;
    top: 12px;
    cursor: pointer;
    z-index: 9;
}

.heart_destinations i {
    color: var(--white-color);
    font-size: 24px;
}

.heart_destinations i:hover {
    color: var(--main-color);
}

.edit_date_form .form-control {
    border: 1px solid var(--black-color);
    margin-top: 10px;
}

.edit_date_form {
    padding-top: 20px;
}

.select_person_right h6 {
    font-size: 16px;
    font-weight: 500;
}

.edit_person {
    text-align: right;
    padding-top: 15px;
}

.edit_person p {
    color: var(--main-color);
    cursor: pointer;
}

.form-control {
    height: 55px;
    border: 2px dashed #dddddd75;
    padding: 10px 22px;
    font-size: 16px;
}

.bg_input {
    background-color: #F3F6FD;
}

.form-control:focus {
    color: var(--heading-color);
    background-color: #fff;
    border: 2px dashed var(--main-color);
    outline: none;
    box-shadow: none;
}

.tour_booking_form_box {
    background: #FFFFFF;
    box-shadow: -4px -5px 14px rgb(0 0 0 / 8%), 5px 8px 16px rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
}

#tour_bookking_form_item .form-group {
    margin-bottom: 30px;
}

#tour_bookking_form_item {
    padding-top: 25px;
}

.booking_tour_form {
    margin-bottom: 30px;
}

.booking_tour_form_submit a {
    margin-top: 15px;
}

.coupon_code_area_booking {
    padding-top: 30px;
}

.coupon_code_submit {
    padding-top: 20px;
}

.tour_booking_amount_area ul {
    padding-top: 15px;
}

.tour_booking_amount_area ul li {
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
    font-weight: 500;
    font-size: 16px;
}

.tour_booking_amount_area ul li:last-child {
    border-bottom: 1px solid #dadada;
    padding: 10px;
    
}

.tour_bokking_subtotal_area {
    padding-top: 15px;
}

.tour_bokking_subtotal_area h6 {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 105px;
}

.coupon_add_area {
    padding-top: 15px;
    border-bottom: 1px solid #dadada;
    padding-bottom: 15px;
}

.coupon_add_area h6 {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.remove_coupon_tour {
    font-size: 14px;
    font-style: italic;
    font-weight: 400 !important;
    color: var(--main-color);
    cursor: pointer;
}

.total_subtotal_booking {
    padding-top: 15px;
}

.total_subtotal_booking h6 {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

/* ================================
          About Page
===================================*/
.about_us_left h5 {
    font-size: 22px;
    font-weight: 500;
}

.about_us_left h2 {
    font-size: 40px;
    line-height: 55px;
    padding-top: 20px;
}

.about_us_left p {
    padding-top: 15px;
}

.about_us_left a {
    margin-top: 25px;
}

.about_offer_banner img {
    width: 100%;
    height: 100%;
}

.about_offer_banner {
    position: relative;
}

.about_offer_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.about_offer_text h3 {
    font-weight: 500;
    color: var(--white-color);
}

.about_offer_text h3 span {
    color: var(--main-color);
}

.about_offer_text h2 {
    color: var(--white-color);
    padding: 15px 0;
}

.about_offer_text a {
    color: var(--white-color);
    border-bottom: 1px solid var(--white-color);
}

.about_offer_text a:hover {
    color: var(--main-color);
    border-bottom: 1px solid var(--main-color);
}

.about_service_boxed {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.06), 8px 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 18px 18px;
}

.about_service_boxed h5 {
    font-size: 20px;
    font-weight: 500;
    padding-top: 15px;
}

.about_service_boxed p {
    padding-top: 10px;
}

.about_offer_banner {
    height: 400px;
}

#consultation_area {
    /* background-image: url('../img/common/counter_bg.png'); */
    padding: 150px 0 220px 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.consultation_area_text {
    text-align: center;
}

.consultation_area_text h2 {
    color: var(--white-color);
}

.consultation_area_text p {
    color: var(--white-color);
    padding-top: 20px;
}

.counter_area_wrapper {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.06), 8px 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.counter_item {
    text-align: center;
    border-right: 1px solid #e9e9e9;
    padding: 35px 0;
}

.counter_item h3 {
    font-size: 35px;
    font-weight: 500;
    color: var(--main-color);
    padding-top: 6px;
    letter-spacing: 2px;
}

.counter_item h6 {
    font-weight: 500;
    font-size: 16px;
    padding-top: 2px;
}

#counter_area {
    margin-top: -100px;
}

/* ==========================
    Tour Guides Page
========================== */
.guide_heading_area {
    text-align: center;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 10px;
    padding-top: 30px;
    margin-bottom: 30px;
}

.guide_heading_area h3 {
    font-weight: 400;
    color: var(--main-color);
}

.tour_guides_boxed {
    position: relative;
    box-shadow: 8px 10px 24px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
}

.tour_guides_boxed img {
    width: 100%;
}

.tour_guide_content {
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    box-shadow: 8px 10px 24px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: var(--transition);
    opacity: 0;
    transform: translateY(100px);
}

.tour_guides_boxed:hover .tour_guide_content {
    opacity: 1;
    transform: translateY(0px);
}

.tour_guide_content h3 {
    font-size: 20px;
    font-weight: 500;
    color: var(--main-color);
}

.tour_guide_content p {
    padding-top: 1px;
    font-size: 14px;
    font-weight: 500;
}

.tour_guide_content ul {
    margin-bottom: 10px;
    margin-top: -25px;
}

.tour_guide_content li {
    display: inline-flex;
    background: #fff;
    width: 30px;
    text-align: center;
    justify-content: center;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.08);
    transition: var(--transition);
    color: var(--main-color);
    margin-left: 8px;
}

.tour_guide_content li:first-child {
    margin-left: 0;
}

.tour_guide_content li:hover {
    background: var(--main-color);
}

.tour_guide_content li:hover a {
    color: var(--white-color);
}

/* =============================
            Faqs Page
==============================*/

.faqs_item_wrapper h3 {
    font-weight: 500;
    font-size: 28px;
}

.faqs_main_item {
    margin-top: 30px;
}

.faqs_main_item .accordion-item {
    background-color: #fff;
    border: none;
    margin-bottom: 30px;
}

.faqs_main_item .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    color: var(--black-color);
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    font-weight: 500;
    box-shadow: none;
}

.faqs_main_item .accordion-header {
    border: 1px solid #e5e5e5;
}

.faqs_main_item button:focus {
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
    border: none;
}

.faqs_item_wrapper {
    padding-top: 50px;
}

.faqs_call_area {
    background: var(--main-color);
    text-align: center;
    padding: 90px 30px;
    border-radius: 10px;
}

.faqs_call_area h5 {
    color: var(--white-color);
    padding-top: 20px;
}

.faqs_call_area h3 a {
    color: var(--white-color);
}

.faqs_call_area h3 a:hover {
    color: var(--black-color);
}

.faqs_call_area h3 {
    padding-top: 20px;
    font-size: 30px;
    font-weight: 500;
}

/* =============================
        Contact Page
================================*/

.contact_boxed {
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 30px 15px;
    text-align: center;
}

.contact_boxed h6 {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 15px;
}

.contact_boxed h3 {
    padding-bottom: 20px;
    font-weight: 500;
}

.contact_boxed p {
    padding-bottom: 15px;
}

.contact_boxed a {
    font-weight: 600;
}

.phone_tuch_area {
    display: flex;
    justify-content: space-between;
    padding-bottom: 35px;
    padding-top: 35px;
}

.phone_tuch_area h3 {
    font-weight: 500;
    font-size: 30px;
}

.contact_main_form_area {
    padding-top: 100px;
}

.contact_form {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.08), 4px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 35px 35px;
    margin-top: 20px;
}

#contact_form_content .form-control {
    margin-bottom: 30px;
}

#contact_form_content textarea {
    height: 100%;
}

.map_modal_content {
    text-align: center;
    padding: 80px 25px 25px 25px;
}

/* =============================
        News Page
================================*/
.news_area_top_right h2 {
    line-height: 45px;
    font-size: 30px;
}

.news_area_top_right h2 a {
    color: var(--black-color);
}

.news_area_top_right h2 a:hover {
    color: var(--main-color);
}

.news_area_top_right p {
    padding-top: 15px;
    padding-bottom: 15px;
}

.news_area_top_right a {
    font-weight: 500;
}

.news_area_top_right a i {
    padding-left: 4px;
}

.news_author_area {
    display: flex;
    padding-top: 20px;
}

.news_author_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}

.news_author_img img {
    width: 100%;
}

.news_author_area_name {
    padding-left: 15px;
}

.news_author_area_name p {
    padding: 4px 0 0 0;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.news_author_area_name p i {
    font-size: 6px;
    padding: 0 8px;
}

.new_main_news_box {
    border-top: 1px solid #dbdbdb;
    margin-top: 50px;
}

.news_item_boxed {
    margin-top: 45px;
}

.news_item_img {
    border-radius: 10px;
    overflow: hidden;
}

.news_item_img img {
    width: 100%;
}

.news_item_content {
    padding: 25px 5px 0px 0;
}

.news_item_content h3 {
    font-weight: 500;
    line-height: 35px;
}

.news_item_content h3 a {
    color: var(--black-color);
}

.news_item_content h3 a:hover {
    color: var(--main-color);
}

.news_item_content p {
    padding-top: 13px;
}

/* =============================
        News Page Two
================================*/
.news_two_content {
    padding: 15px 0 0 0;
}

.news_two_content h2 {
    line-height: 56px;
}

.news_two_content h2 a {
    color: var(--black-color);
}

.news_two_content h2 a:hover {
    color: var(--main-color);
}

.news_two_author {
    display: flex;
    align-items: center;
}

.news_two_author p {
    padding: 0px 0 0 15px;
}

.news_sidebar_search {
    padding-top: 25px;
}

.news_sidebar_heading {
    border-bottom: 1px solid #d1d1d18a;
}

.news_sidebar_heading h3 {
    font-weight: 500;
    padding-bottom: 10px;
    display: inline-flex;
    position: relative;
}

.news_sidebar_heading h3::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--main-color);
    left: 0;
    bottom: -1px;

}

.news_sidebar_content {
    padding-bottom: 50px;
}

.news_sidebar_search form .form-group {
    position: relative;
}

.news_sidebar_search form input {
    padding: 0 50px 0 20px;
    border-radius: 15px;
}

.news_sidebar_search form button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.news_sidebar_content_inner {
    padding-top: 25px;
}

.news_sidebar_category li a {
    background-color: #F3F6FD;
    margin-bottom: 10px;
    display: block;
    padding: 14px 20px;
    color: var(--black-color);
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    transition: var(--transition);
    border-radius: 12px;
    border-left: 5px solid var(--main-color);
}

.news_sidebar_category li a:hover {
    color: var(--main-color);
    transform: translateX(10px);
}

.news_sidebar_tags li {
    display: inline-flex;
    margin-bottom: 15px;
    margin-right: 10px;
}

.news_sidebar_tags li a {
    background-color: #F3F6FD;
    padding: 10px 22px;
    color: var(--heading-color);
    font-family: 'Poppins', sans-serif;
    transition: var(--transition);
    border-radius: 10px;
}

.news_sidebar_tags li a:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.sidebar_advasting_area a img {
    width: 100%;
}

.news_two_card .news_item_boxed {
    margin-top: 0px;
    margin-bottom: 40px;
}

/* =============================
        News Page Three
================================*/
.news_tabs_boxed .news_item_boxed {
    margin-bottom: 30px;
    margin-top: 0;
}

/* =============================
        News Details Page
================================*/
.news_details_content_area h2 {
    padding-top: 30px;
    line-height: 54px;
}

.news_details_content_area p {
    padding-top: 20px;
}

.news_details_content_area h3 {
    padding-top: 20px;
    font-weight: 500;
}

.news_details_content_area ul li {
    padding-top: 13px;
}

.news_details_content_area ul li i {
    font-size: 10px;
    padding-right: 7px;
    color: var(--main-color);
}

.news_details_left_img {
    padding-top: 30px;
}

.download_pdf_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #EEF4F8;
    padding: 20px 20px;
    border-radius: 12px;
    margin-top: 45px;
}

.downloads_pdf_icon {
    display: flex;
    align-items: center;
}

.downloads_pdf_icon h3 {
    padding-left: 10px;
    font-weight: 500;
}

.comment_area {
    padding-top: 60px;
}

.comment_area h3 {
    font-weight: 500;
    margin-bottom: 30px;
}

.comment_area_boxed {
    display: flex;
    padding-bottom: 35px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 35px;
}

.comment_area .comment_area_boxed:last-child {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
}

.comment_img {
    width: 17%;
}

.comment_author_name {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.comment_text {
    padding-left: 20px;
}

.comment_area_form {
    padding-top: 60px;
}

.comment_area_form h3 {
    font-weight: 500;
    margin-bottom: 30px;
}

textarea {
    height: 100% !important;
}

#news_comment_form .form-froup {
    margin-bottom: 30px;
}

.news_details_right_item {
    background: #FFFFFF;
    box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.08), 8px 10px 24px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 30px 20px;
    margin-bottom: 30px;
}

.news_details_right_item h3 {
    font-weight: 500;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
}

.recent_news_item {
    display: flex;
    padding-top: 25px;
}

.recent_news_img {
    width: 35%;
}

.recent_news_text {
    padding-left: 10px;
}

.recent_news_text h5 {
    font-weight: 500;
    line-height: 26px;
}

.recent_news_text h5 a {
    color: var(--black-color);
}

.recent_news_text h5 a:hover {
    color: var(--main-color);
}

.recent_news_text p {
    padding-top: 0px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.recent_news_text p i {
    font-size: 6px;
    margin: 0 7px;
    color: var(--main-color);
}

.news_tags_area {
    padding-top: 10px;
}

.news_tags_area ul li {
    display: inline-flex;
    margin-right: 10px;
    margin-top: 15px;
}

.news_tags_area ul li a {
    background: #DDDDDD;
    padding: 7px 15px;
    color: var(--black-color);
    font-weight: 400;
    transition: var(--transition);
}

.news_tags_area ul li a:hover {
    background: var(--main-color);
    color: var(--white-color);
}

.news_tags_area ul li a.active {
    background: var(--main-color);
    color: var(--white-color);
}

.share_icon_area li:first-child {
    margin-left: 0;
}

.share_icon_area {
    padding-top: 30px;
}

.share_icon_area li {
    display: inline-flex;
    background: #fff;
    width: 40px;
    text-align: center;
    justify-content: center;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    box-shadow: 4px 6px 20px rgb(0 0 0 / 8%);
    transition: var(--transition);
    color: var(--main-color);
    margin-left: 15px;
}

.share_icon_area li:hover {
    background: var(--main-color);
}

.share_icon_area li:hover a {
    color: var(--white-color);
}

/* ==============================
        Dashboard Page
================================*/
.dashboard_sidebar {
    background: #FFFFFF;
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.dashboard_sidebar_user {
    text-align: center;
    padding: 30px 20px;
    background: #F3F6FD;
}

.dashboard_sidebar_user img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
}

.dashboard_sidebar_user h3 {
    padding-top: 20px;
    font-weight: 500;
}

.dashboard_sidebar_user p {
    line-height: 17px;
    padding-top: 10px;
}

.dashboard_sidebar_user p a {
    color: var(--paragraph-color);
}

.dashboard_sidebar_user p a:hover {
    color: var(--main-color);
}

.dashboard_menu_area {
    padding: 40px 20px;
}

.dashboard_menu_area ul li {
    margin-bottom: 30px;
    font-size: 18px;
}

.dashboard_menu_area ul li:last-child {
    margin-bottom: 0px;
}

.dashboard_menu_area ul li:hover {
    color: var(--main-color);
    cursor: pointer;
}

.dashboard_menu_area ul li a {
    color: var(--black-color);
}

.dashboard_menu_area ul li a.active {
    color: var(--main-color);
}

.dashboard_menu_area ul li a:hover {
    color: var(--main-color);
}

.dashboard_menu_area ul li i {
    padding-right: 10px;
}

.booing_sidebar_dashboard {
    padding-top: 30px;
    padding-left: 30px;
}

.dashboard_dropdown_button {
    position: relative;
}

.dashboard_dropdown_button span {
    position: absolute;
    right: 0;
    display: block;
    top: 0;
}

.dashboard_top_boxed {
    background: #FFFFFF;
    box-shadow: -4px -4px 14px rgba(0, 0, 0, 0.08), 8px 8px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 25px 40px;
    display: flex;
    align-items: center;
}

.dashboard_main_top {
    margin-bottom: 30px;
}

.dashboard_top_icon i {
    font-size: 100px;
    color: var(--paragraph-color);
}

.dashboard_top_text {
    padding-left: 20px;
}

.dashboard_top_text h3 {
    padding-bottom: 8px;
    font-weight: 500;
    color: var(--paragraph-color);
}

.dashboard_top_text h1 {
    line-height: 64px;
    font-size: 70px;
    font-weight: 500;
}

.dashboard_common_table {
    background: #F3F6FD;
    border-radius: 12px;
    padding: 25px 30px;
}

.dashboard_common_table h3 {
    font-weight: 500;
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 11px;
    position: relative;
}

.dashboard_common_table h3::after {
    content: "";
    width: 140px;
    height: 2px;
    background: var(--main-color);
    position: absolute;
    left: 0;
    bottom: 0;
}

.table_common_area {
    margin-top: 40px;
}

.table_common_area table {
    text-align: center;
    border: 1px solid #d5d5d5;
}

.table_common_area thead tr th {
    border: none;
    background: #fff;
    padding: 15px 0;
}

.table_common_area tbody tr td {
    padding: 16px 0;
}

.table_common_area tbody tr td.complete {
    color: #4CAF50;
    font-weight: 500;
}

.table_common_area tbody tr td.cancele {
    color: #C8102E;
    font-weight: 500;
}

.table_common_area tbody tr td i {
    cursor: pointer;
}

/* ---My Profile Page--- */
#profile_form_area {
    padding-top: 35px;
}

#profile_form_area .form-group {
    margin-bottom: 30px;
}

#profile_form_area label {
    margin-bottom: 10px;
}

#profile_form_area .change_password_field {
    position: relative;
}

#profile_form_area .change_password_field p {
    position: absolute;
    bottom: 14px;
    right: 15px;
    font-size: 12px;
    cursor: pointer;
    color: var(--main-color);
    font-weight: 500;
}

.change_password_input_boxed h3 {
    margin-bottom: 30px;
}

.notification_top_heading {
    position: relative;
}

.notification_top_heading p {
    color: var(--main-color);
    padding-left: 20px;
    position: absolute;
    left: 147px;
    top: 0;
    font-weight: 600;
    font-size: 12px;
}

.notification_wrapper {
    padding-top: 30px;
}

.notification_wrapper .accordion-item {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, .125);
    margin-bottom: 15px;
}

.notification_wrapper .accordion-button {
    padding: 4px 25px;
    font-size: 16px;
    background-color: transparent;
}

.notification_wrapper .accordion-button.active {
    background: var(--white-color);
}

.notification_wrapper .accordion-button.shows {
    background: var(--white-color);
}

.notification_wrapper .accordion-button:focus {
    z-index: 3;
    border-color: none;
    outline: 0;
    background: #FFFFFF;
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
}

.notification_wrapper .accordion-button::after {
    display: none;
}

.modal {
    z-index: 99999;
}

.logout_modal_content {
    text-align: center;
    padding: 80px 0px 40px 0;
}

.logout_modal_content h3 {
    font-weight: 400;
    font-size: 35px;
    line-height: 45px;
}

.logout_approve_button {
    display: flex;
    justify-content: space-between;
    padding: 40px 30px 0 30px;
}

.logout_approve_button button {
    width: 46%;
}

.btn_border {
    border: 1px solid var(--main-color);
}

.btn_border:hover {
    background: var(--main-color);
}

/* --wallet Page-- */
.wallet_area_boxed {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin-top: 30px;
    padding: 25px 15px;
}

.wallet_area_boxed h4 {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 11px;
    position: relative;
}

.wallet_area_boxed h4::after {
    content: "";
    width: 100px;
    height: 2px;
    background: var(--main-color);
    position: absolute;
    left: 0;
    bottom: 0;
}

.wallet_blance_boxed {
    margin-top: 20px;
}

.wallet_blance_boxed p {
    font-size: 14px;
}

.wallet_blance_boxed h5 {
    font-weight: 500;
    padding-top: 4px;
}

.wallet_boxed_flex {
    display: flex;
    justify-content: space-between;
}

.dashboard_price_range {
    padding-top: 30px;
}

.main_range_price {
    height: 13px;
    width: 100%;
    background: #66666652;
    border-radius: 40px;
    font-size: 10px;
    line-height: 100%;
    position: relative;
    overflow: hidden;
}

.main_range_price::after {
    content: "30%";
    width: 49%;
    background: var(--main-color);
    height: 13px;
    position: absolute;
    border-radius: 30px;
    text-align: right;
    color: #fff;
    padding: 0 22px;
    line-height: 12px;
}

.price_range_blance {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price_range_blance p {
    font-size: 12px;
    font-weight: 600;
}

.add_balance_area {
    padding-top: 30px;
}

.add_balance_area .input-group-text {
    padding: 0 16px;
    font-size: 20px;
    border: none;
}

.add_balance_area .form-control {
    height: 45px;
}

.other_add_balance_area {
    text-align: center;
    padding-top: 7px;
}

.other_add_bal_button {
    padding-top: 5px;
    padding-bottom: 20px;
}

.btn_add_bal {
    border: 1px solid var(--main-color);
    font-size: 14px;
    margin-left: 10px;
    transition: var(--transition);
}

.btn_add_bal:hover {
    background: var(--main-color);
}

.btn_add_bal.active {
    background: var(--main-color);
    color: var(--white-color);
}

.wallet_table_top {
    padding-top: 50px;
}

.btn_modal_closed {
    position: absolute;
    right: 20px;
    top: 16px;
}

.btn_modal_closed button {
    background: var(--white-color);
    border: 2px solid var(--main-color);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 23px;
}

.btn_modal_closed button:hover {
    background: var(--main-color);
    color: var(--white-color);
    transition: var(--transition);
}

/* ============================
        Login Page
================================ */
.common_author_boxed {
    background: #f3f6fd;
    padding: 45px 20px;
}

.common_author_heading {
    text-align: center;
}

.common_author_heading h3 {
    font-weight: 400;
    color: var(--paragraph-color);
}

.common_author_heading h2 {
    padding-top: 15px;
}

.common_author_form {
    padding: 50px 100px 0px 100px;
}

#main_author_form .form-group {
    margin-bottom: 20px;
    text-align: right;
}

#main_author_form .form-group a {
    color: var(--paragraph-color);
    padding-top: 10px;
    display: block;
}

#main_author_form .form-group a:hover {
    color: var(--main-color);
}

.common_form_submit {
    text-align: center;
}

.common_form_submit {
    padding-top: 20px;
}

.have_acount_area {
    text-align: center;
    padding-top: 20px;
}

.other_author_option ul {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.other_author_option ul li {
    padding-left: 10px;
    transition: var(--transition);
}

.other_author_option ul li:hover {
    transform: translateY(-10px);
}

.line_or {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
}

.line_or span {
    display: block;
    background: #f3f6fd;
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 50%;
    color: var(--black-color);
    font-size: 20px;
    margin: 0px auto -21px auto;
}

.otpCont {
    text-align: center;
    display: flex;
    justify-content: center;
}

.otSc {
    margin: 0;
    margin-right: 34px;
    border: 1px solid var(--main-color);
    padding: 5px 0px;
    font-size: 22px;
    text-align: center;
    width: 7%;
    outline: none;
}

.otSc:last-child {
    margin-right: 0;
}

/* ==============================
     Flight Search Area
================================*/

.flight_search_items {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flight_search_item_wrappper {
    margin-bottom: 30px;
}

.flight_search_left {
    display: flex;
    align-items: center;
    padding: 0 0px 0 23px;
}

.flight_search_middel {
    display: flex;
    align-items: center;
    padding-left: 47px;
}

.flight_multis_area_wrapper {
    display: flex;
    justify-content: space-between;
}

.flight_search_destination {
    padding-left: 33px;
}

.flight_search_destination p {
    font-size: 14px;
    line-height: 16px;
}

.flight_search_destination h3 {
    font-weight: 500;
    padding-top: 5px;
}

.flight_search_destination h6 {
    padding-top: 5px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 140px;
}

.flight_right_arrow {
    text-align: center;
}

.flight_right_arrow img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.1);
}

.flight_right_arrow p {
    font-size: 11px;
    line-height: 18px;
}

.flight_right_arrow h6 {
    font-size: 11px;
    font-weight: 700;
    padding-top: 10px;
}

.flight_search_right {
    background: #F3EFF9;
    padding: 25px 16px 20px 25px;
}

.flight_search_right h5 {
    font-size: 18px;
    font-weight: 500;
    color: var(--paragraph-color);
}

.flight_search_right h2 {
    padding-top: 10px;
}

.flight_search_right h2 sup {
    font-size: 13px;
    color: var(--main-color);
    font-weight: 600;
    top: -19px;
    padding-left: 5px;
}

.flight_search_right a {
    margin-top: 13px;
}

.flight_search_right p {
    font-size: 12px;
    padding-top: 2px;
    font-weight: 500;
    color: var(--black-color);
}

.flight_search_right h6 {
    font-size: 16px;
    font-weight: 500;
    padding-top: 7px;
    cursor: pointer;
}

.load_more_flight {
    text-align: center;
    padding-top: 40px;
}

.load_more_flight button {
    border: 1px solid var(--main-color);
}

.load_more_flight button:hover {
    background: var(--main-color);
}

.flight_policy_refund {
    border: 1px solid #d7d6d6;
    border-radius: 10px;
    padding: 0px 20px;
}

.airline-details {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.airline-details .img {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}

.airline-details .airlineName {
    margin-right: 8px;
    font-size: 11px;
    font-weight: 600;
}

.airline-details .flightNumber {
    font-size: .625rem;
    color: #4c4c4c;
}

.flight_inner_show_component .flight_det_wrapper {
    display: flex;
    align-items: flex-start;
    width: 185px;
}

.flight_inner_show_component .flight_det_wrapper .flight_det .code_time {
    font-size: 18px;
}

.flight_inner_show_component .flight_det_wrapper .flight_det .code_time .code {
    color: #4c4c4c;
    margin-right: 8px;
}

.flight_inner_show_component .flight_det_wrapper .flight_det .code_time .time {
    font-weight: 600;
}

.flight_inner_show_component .flight_det_wrapper .flight_det .airport {
    color: #4c4c4c;
    font-size: .75rem;
    line-height: 16px;
}

.flight_inner_show_component .flight_det_wrapper .flight_det .date {
    color: #4c4c4c;
    font-size: .625rem;
}

.flight_inner_show_component .flight_duration {
    justify-content: center;
    width: 96px;
    text-align: center;
    align-items: flex-start;
    color: rgb(76, 76, 76);
    flex-wrap: wrap;
    line-height: 1;
}

.flight_inner_show_component .flight_duration span {
    font-size: 0.625rem;
    color: rgb(76, 76, 76);
    margin-top: 8px;
    font-weight: 500;
    display: inline-block;
}

.flight_inner_show_component .arrow_right {
    flex-basis: 88px;
    margin: 4px 1rem 0px;
    /* background-image: url('../img/icon/plane-right-blue.png'); */
    background-repeat: no-repeat;
    background-position: 50% center;
    text-align: center;
    min-height: 24px;
    min-width: 24px;
    background-size: 24px;
}

.flight_inner_show_component {
    display: flex;
    align-items: flex-start;
    position: relative;
}

.TabPanelInner p {
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 16px
}

.TabPanelInner h4 {
    font-size: 16px;
    padding-bottom: 4px;
    border-bottom: 1px solid #8b3eea;
    display: inline-block;
    margin-bottom: 12px;
}

.flight_show_down_wrapper {
    display: flex;
    border-bottom: 1px solid #cdcdcd;
    padding-top: 25px;
}

.flight_refund_policy {
    display: flex;
    justify-content: space-between;
}

.flex_widht_less {
    width: 68%;
    padding-left: 22px;
}

.flight_info_taable h3 {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 4px;
}

.fligth_top_search_main_form_wrapper .flight_categories_search {
    position: inherit;
    right: 30px;
    top: 0;
    margin-bottom: 20px;
}

/* ===========================
   Flight Details Page
===========================*/
.flight_sidebar_right {
    padding-top: 20px;
    display: flex;
}

.flight_right_arrow_sidebar {
    text-align: center;
    padding: 0 22px;
}

.flight_right_arrow_sidebar img {
    width: 40px;
}

.flight_right_arrow_sidebar h6 {
    font-size: 10px;
}

.flight_right_arrow_sidebar p {
    font-size: 10px;
    line-height: 13px;
}

.flight_search_destination_sidebar p {
    font-size: 10px;
    font-weight: 600;
    color: var(--black-color);

}

.flight_search_destination_sidebar h3 {
    font-size: 16px;
    font-weight: 500;
}

.flight_search_destination_sidebar h6 {
    font-size: 10px;
    padding-top: 3px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
}

.flight_search_middel_sidebar {
    display: flex;
}

/* ===================================
        Hotel Search Page
====================================*/
.room_book_item {
    display: flex;
    border: 1px solid #DDDDDD;
    margin-top: 30px;
}


.room_booking_heading h3 {
    font-size: 20px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 335px;
    padding-bottom: 10px;
}

.room_booking_heading h3 a {
    color: var(--black-color);
}

.room_booking_heading h3 a:hover {
    color: var(--main-color);
}

.room_fasa_area ul {
    display: flex;
    padding-top: 10px;
}

.room_fasa_area ul li {
    padding-right: 20px;
}

.room_fasa_area ul li img {
    padding-right: 8px;
    width: 28px;
}

.room_person_select {
    padding-left: 35px;
    padding-top: 15px;
}

.room_person_select h3 {
    font-size: 18px;
    color: var(--main-color);
    font-weight: 600;
    padding-bottom: 15px;
}

.room_booking_right_side {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px 0 25px;
}

.room_select_area .nav-tabs {
    border-bottom: none;
    margin-bottom: 20px;
    justify-content: center;
}

.room_select_area .nav-tabs .nav-item.show .nav-link,
.room_select_area .nav-tabs .nav-link.active {
    color: var(--white-color);
    background-color: var(--main-color);
    border-color: #dee2e6 #dee2e6 #fff;
}

.room_select_area .nav-tabs .nav-link {
    margin-bottom: 0;
    background: #F3F6FD;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 10px 110px;
}

.room_details_facilities {
    display: flex;
    justify-content: space-between;
}

.tour_details_boxed_inner .room_details_facilities p {
    padding-bottom: 0px;
}

/* ===================================
    Room-Booking-Confirmation Page
====================================*/

.booking_success_arae {
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

.booking_success_text {
    padding-left: 30px;
}

.booking_success_text h3 {
    font-weight: 500;
    padding-bottom: 10px;
}

.your_info_arae ul li {
    border-bottom: 1px solid #dfdfdf94;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
}

.your_info_arae ul li:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
}

.name_first {
    width: 40%;
}

.last_name {
    font-weight: 600;
}

/* ===================================
    Become A Vendor Page
====================================*/
.how_it_boxed {
    text-align: center;
    padding-top: 30px;
}

.how_it_boxed h3 {
    padding-top: 30px;
    font-weight: 500;
}

.how_it_boxed p {
    padding-top: 10px;
}

.video_play_area {
    position: relative;
}

.video_play_area img {
    width: 100%;
}

.video_play_button {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.video_play_button a {
    color: var(--white-color);
}

.video_play_button a:hover {
    color: var(--main-color);
}

.video_play_button h4 {
    color: var(--white-color);
    padding-top: 35px;
}

.video_play_button h2 {
    color: var(--white-color);
    padding-top: 10px;
    font-size: 40px;
}

.vendor_form_heading {
    padding-bottom: 30px;
}

.vendor_form_heading p {
    padding-top: 10px;
}

.vendor_img img {
    width: 100%;
}

/* ===================================
    Terms Of Service Page
====================================*/
.terms_item h4 {
    font-weight: 700;
}

.terms_item p {
    padding-top: 15px;
}

.terms_item {
    padding-top: 30px;
}

.terms_item:first-child {
    padding-top: 0px;
}

/* ===================================
       Error Page
====================================*/
.error_content h2 {
    padding-top: 30px;
}

.error_content p {
    padding-top: 10px;
}

.error_content a {
    margin-top: 30px;
}




/* --Preloader -- */

.preloader {
    position: fixed;
    z-index: 999999;
    background-color: var(--white-color);
    width: 100%;
    height: 100%;
    text-align: center;
    left: 0;
    right: 0
}

.preloader .lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px
}

.preloader .lds-spinner div {
    -webkit-transform-origin: 40px 40px;
    transform-origin: 40px 40px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
    animation: lds-spinner 1.2s linear infinite
}

.preloader .lds-spinner div::after {
    content: " ";
    display: block;
    position: absolute;
    top: 5px;
    left: 35px;
    width: 5px;
    height: 20px;
    border-radius: 20%;
    background: var(--main-color)
}

.preloader .lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s
}

.preloader .lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

.preloader .lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-animation-delay: -.9s;
    animation-delay: -.9s
}

.preloader .lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s
}

.preloader .lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
    -webkit-animation-delay: -.7s;
    animation-delay: -.7s
}

.preloader .lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    -webkit-animation-delay: -.6s;
    animation-delay: -.6s
}

.preloader .lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-delay: -.5s;
    animation-delay: -.5s
}

.preloader .lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
    -webkit-animation-delay: -.4s;
    animation-delay: -.4s
}

.preloader .lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
    -webkit-animation-delay: -.3s;
    animation-delay: -.3s
}

.preloader .lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    -webkit-animation-delay: -.2s;
    animation-delay: -.2s
}

.preloader .lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
    -webkit-animation-delay: -.1s;
    animation-delay: -.1s
}

.preloader .lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
    -webkit-animation-delay: 0s;
    animation-delay: 0s
}

@-webkit-keyframes lds-spinner {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes lds-spinner {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    vertical-align: middle;
}

/* --Top To Bottom-- */

.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 15px;
    color: #fff;
    background-color: var(--main-color);
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .9s;
    transition: .9s;
    border-radius: 50%
}

.go-top.active {
    top: 98%;
    -webkit-transform: translateY(-98%);
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible
}

.go-top i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: .6s;
    transition: .6s
}

.go-top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 60%
}

.go-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #393953;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .6s;
    transition: .6s;
    border-radius: 50%
}

.go-top:hover,
.go-top:focus {
    color: #fff
}

.go-top:hover::before,
.go-top:focus::before {
    opacity: 1;
    visibility: visible
}

.go-top:hover i:first-child,
.go-top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden
}

.go-top:hover i:last-child,
.go-top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%
}




/*=====================
 homepage-5
 ====================== */
/* banner area  */
#home_five_banner {
    /* background-image: url("../img/home-five/common/banner-five-bg.png"); */
    padding-top: 170px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 100px;
}

.banner_five_text h1 {
    font-size: 100px;
    font-weight: 800;
    line-height: 120px;
    color: var(--black-color);
    padding-bottom: 24px;
}

.banner_five_img {
    text-align: end;
}

.home_five_btn {
    margin-top: 24px;
}

.home_five_btn .btn_theme_transparent {
    color: var(--black-color);
    border: 1px solid var(--main-color);
}

.home_five_btn .btn_theme_transparent:hover {
    color: var(--white-color);
    border: 1px solid var(--main-color);
}

/* form area */
#theme_search_form_five {
    margin-bottom: -140px;
    z-index: 9;
}

/* popular destination area  */
.section-icon {
    position: absolute;
    left: 22%;
    bottom: 54%;
}

.section_heading_five {
    padding-bottom: 30px;
    position: relative;
}

.section_heading_five h5 {
    color: var(--main-color);
    padding-bottom: 16px;
}

.section_heading_five h2 {
    position: relative;
    padding-bottom: 16px;
    color: var(--black-color);
    line-height: 46px;
}

.section_heading_five h2::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    background-color: var(--main-color);
    height: 2px;
    width: 80px;
}


.section_heading_five h2::after {
    position: absolute;
    /* content: url(../img/icon/section-icon.png); */
    bottom: 23px;
}

#popular_destination {
    /* background-image: url("../img/home-five/common/destination-bg.png"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 236px;
}

.popular_destination_img {
    position: relative;
}

.popular_destination_img img {
    width: 100%;
    height: auto;
}

.popular_destination_card:hover .popular_destination_img img {
    transform: scale(1.1);
}

.popular_destination_img img {
    width: 100%;
    transition: var(--transition);
}

.popular_destination_img {
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
}

.destination_ratings {
    position: absolute;
    top: 12px;
    right: 20px;
    background-color: var(--white-color);
    padding: 4px 12px;
    border-radius: 4px;
}

.destination_ratings p i {
    color: var(--main-color);
    font-size: 14px;
}

.destination_ratings p span {
    color: #04010C;
    font-size: 14px;
    font-weight: 500;
}

.popular_destination_card {
    position: relative;
}

.popular_destination_text {
    position: absolute;
    bottom: 12px;
    left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-color);
    width: 90%;
    margin: auto;
    padding: 16px 18px;
    border-radius: 8px;
    opacity: 0;
    transition: var(--transition);
}

.popular_destination_text h5 {
    line-height: 26px;
    padding-right: 8px;
}

.popular_destination_text h5 a {
    color: var(--black-color);
}

.popular_destination_text h5 a:hover {
    color: var(--main-color);
}

.popular_destination_card:hover .popular_destination_text {
    opacity: 1;
}

.destination_card_two {
    margin-top: 40px;
}

/* about section  */
#five_about_area {
    /* background-image: url("../img/home-five/common/about-five-bg.png"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#five_about_area .section_heading_five h2::after {
    position: absolute;
    /* content: url(../img/icon/section-icon.png); */
    top: -13px;
    right: 0px;
}

.five_about_img img {
    width: 125%;
}

.section_heading_five p {
    padding-top: 24px;
    line-height: 32px;
}

.about_five_item {
    display: flex;

}

.about_item_one {
    margin-bottom: 70px;
    position: relative;
}

.about_item_one::after {
    position: absolute;
    top: 71px;
    left: 33px;
    height: 100%;
    /* content: url(../img/icon/about_dote_img.png); */

}

.hotel_img,
.flight_img {
    width: 24%;
}

.about_five_info {
    position: relative;
    border-bottom: 1px dashed var(--main-color);
    padding-bottom: 30px;
}

.about_dote_img {
    position: absolute;
    bottom: 43%;
    left: 34px;
}

.about_five_bottom {
    display: flex;
    padding-top: 50px;
    gap: 60px;
}

.about_five_text h3 {
    font-weight: 500;
    color: var(--black-color);
    line-height: 32px;
}

/* top place */
#top_place .section_heading_five h2::before {
    position: absolute;
    left: 47%;
    bottom: 0;
    content: "";
    background-color: var(--main-color);
    height: 2px;
    width: 80px;
}

.top_place_btn {
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

#top_place {
    background-color: var(--bg-color);
}


.common_card_five {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;
}

.common_card_five_img {
    overflow: hidden;
}

.common_card_five_img a img {
    width: 100%;
    transition: var(--transition);
}

.common_card_five:hover .common_card_five_img a img {
    transform: scale(1.1);
}

.common_card_five_text {
    padding: 20px 10px;
}

.common_card_five_list li {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #727178;
    align-items: center;
}

.common_card_five_list li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px;
}

.common_card_five_text h3 {
    padding-top: 5px;
}

.common_card_five_text h3 a {
    font-size: 20px;
    color: var(--black-color);
    font-weight: 400;
}

.common_card_five:hover .common_card_five_text h3 a {
    color: var(--main-color);
}

.common_card_five_text p {
    font-size: 14px;
    padding-top: 5px;
}

.common_card_five_text p i {
    font-size: 14px;
    padding-right: 5px;
}

.common_card_five_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 12px;
}

.common_card_five_bottom_left p {
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
}

.common_card_five_bottom_left h6 {
    color: #727178;
    font-size: 12px;
    padding-top: 3px;
}

.common_card_five_bottom_right h4 sub {
    font-size: 12px;
}

#top_place .section_heading_five {
    padding-bottom: 0px;
}

/* why choose us section */
#best_travel_section {
    /* background-image: url("../img/home-five/common/best_travel_bg.png"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 50px;
    margin: 50px 90px 0px 60px;
    ;
}

#best_travel_section .section_heading_five h2::after {
    position: absolute;
    /* content: url(../img/icon/section-icon.png); */
    top: -17px;
    right: 32px;
}

#best_travel_section .section_heading_five {
    padding: 30px 0;
}

#best_travel_section .section_heading_five h2 {
    font-size: 24px;
    line-height: 36px;
    padding-right: 30px;
}

.travel_list_item li img {
    margin-right: 8px;
}

.travel_list_item li {
    padding-bottom: 20px;
}

.benefit_list {
    display: flex;
    gap: 12px;
    padding: 30px 0;
    border-bottom: 1px dashed #DDDDDD;
}

.benefit_list:last-child {
    border-bottom: none;
}

.location_img,
.price_img,
.calendar_img {
    width: 40%;
}

.benefit_text {
    padding-right: 60px;
}

.benefit_text h3 {
    color: var(--main-color);
    font-weight: 500;
    padding-bottom: 8px;
}

/* our process area */
#process-area .section_heading_five h2::before {
    position: absolute;
    left: 47%;
    bottom: 0;
    content: "";
    background-color: var(--main-color);
    height: 2px;
    width: 80px;
}

#process-area {
    background-color: var(--bg-color);
}

.process_card h3 {
    font-weight: 500;
    padding: 30px 0px 20px 0px;
}

.process_card h3 a {
    color: var(--black-color);
    transition: var(--transition);
}

.process_card h3 a:hover {
    color: var(--main-color);
}

.process_card p {
    line-height: 32px;
}

/* nearby destination */

#Nearby_destination .section_heading_five {
    padding-bottom: 0px;
}

#Nearby_destination .button_style_top_left.owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: -56px;
}

/* download area */

#download_area .section_heading_five h2::after {
    position: absolute;
    /* content: url(../img/icon/section-icon.png); */
    top: -16px;
    right: 32px;
}

#download_area {
    /* background-image: url("../img/home-five/common/download-bg.png"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.app_download_area {
    display: flex;
    align-items: center;
    gap: 30px;
}

.app_download_right {
    padding-right: 98px;
}

.phone_img {
    margin-bottom: -125px;
}

.apple_app,
.play_store_app {
    transition: var(--transition);
}

.apple_app:hover {
    transform: scale(1.1);
}

.play_store_app:hover {
    transform: scale(1.1);
}

#footer_area_bg {
    padding: 100px 0px 50px 0px;
    background-color: var(--bg-color);
}

/*====================
 homepage six 
 =================*/

/* banner six area */

.banner_six_slider_item {
    padding: 184px 0 244px 0;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner_six_text h1 {
    color: var(--white-color);
    font-size: 96px;
    font-weight: 500;
    line-height: 120px;
    padding-bottom: 20px;
}

.banner_six_text p {
    color: var(--white-color);
    line-height: 30px;
}

#home_six_banner .owl-item.active .banner_six_slider_item .banner_six_text .slider-sttle {
    -webkit-animation: 1s 1.1s bounceInLeft both;
    animation: 1s 1.1s bounceInLeft both;
}

#home_six_banner .owl-item.active .banner_six_slider_item .banner_six_text .slider-pararp {
    -webkit-animation: 1s 1.5s fadeInUp both;
    animation: 1s 1.5s fadeInUp both;
}

#home_six_banner .owl-theme .owl-nav button.owl-prev {
    position: absolute;
    bottom: 450px;
    right: 60px;
}

#home_six_banner .owl-theme .owl-nav button.owl-next {
    position: absolute;
    bottom: 245px;
    right: 60px;
}

#home_six_banner .owl-carousel .owl-nav button.owl-next,
#home_six_banner .owl-carousel .owl-nav button.owl-prev {
    background: 0 0;
    color: var(--black-color);
    font-size: 18px;
    height: 25px;
    width: 25px;
    line-height: 25px;
    border-radius: 0px;
    transition: var(--transition);
}

#home_six_banner .owl-carousel .owl-nav button.owl-next:hover,
#home_six_banner .owl-carousel .owl-nav button.owl-prev:hover {
    color: var(--white-color);
}

/* service six area */
.home_six_section_heading h5 {
    display: inline-block;
    background-color: #E8D8F8;
    color: var(--main-color);
    padding: 8px 38px;
    border-radius: 30px;
    margin-bottom: 12px;
}

.home_six_section_heading h2 {
    position: relative;
    padding-bottom: 12px;
    line-height: 45px;
}

.home_six_section_heading h2::after {
    position: absolute;
    background-color: var(--main-color);
    height: 2px;
    width: 100px;
    content: " ";
    bottom: 0;
    left: 47%;
}

.home_six_service_boxed {
    border: 1px solid #dfdfdf;
    padding: 20px 16px;
    border-radius: 8px;
    position: relative;
    margin-top: 30px;
    transition: var(--transition);
}

.home_six_service_boxed::after {
    position: absolute;
    content: "";
    height: 65px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #F3F6FD;
    z-index: 1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.home_six_service_boxed a {
    position: relative;
    z-index: 9;
}

.home_six_service_boxed a i {
    padding-left: 8px;
}

.home_six_service_boxed img {
    margin-bottom: 20px;
    transition: var(--transition);
}

.home_six_service_boxed h3 {
    font-weight: 500;
    padding-bottom: 16px;
}

.home_six_service_boxed p {
    font-size: 16px;
    line-height: 32px;
    padding-bottom: 55px;
}

.home_six_service_boxed:hover {
    transform: translateY(-10px);
}


.home_six_service_boxed:hover img {
    transform: translateY(5px);
}



.home_six_service_img {
    margin-top: 25px;
}

/* home six about area */

#home_six_about_area {
    /* background-image: url("../img/home-six/common/about-six-bg.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.home_six_about_left {
    position: relative;
}

.home_six_about_left img {
    width: 100%;
}

.about_six_content {
    padding-top: 26px;
}

.home_six_about_right .home_six_section_heading h2::after {
    left: 0;
}

.about_six_content p {
    line-height: 32px;
}

.about_six_list_item {
    display: flex;
    align-items: center;
    gap: 100px;
}

.about_six_list_item ul li {
    padding-top: 20px;
}

.about_six_list_item ul li img {
    margin-right: 8px;
}

.about_six_para {
    padding: 16px 0px 22px 0px;
    border-bottom: 2px dashed var(--main-color);
}

.about_six_about_author {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 100px;
    padding-top: 26px;
}

.home_six_video_area {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--main-color);
    padding: 60px 90px;
    border-bottom-right-radius: 12px;
}

.home_six_video_play_area i {
    color: var(--main-color);
    position: relative;
    z-index: 99;
    font-size: 18px;
}


.home_six_video_play_area:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.home_six_video_play_area:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    transition: all 200ms;
}

/* listing area  */
.theme_nav_tab_six {
    padding-bottom: 0;
}

.theme_nav_tab_six_item .nav-tabs {
    border-bottom: none;
    justify-content: flex-end;
}

.theme_nav_tab_six_item .nav-tabs .nav-link {
    background: #FBF9FE;
    margin-left: 17px;
    border: none;
    border-radius: 20px;
    color: #2B2540;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 24px;
}

.theme_nav_tab_six_item .nav-tabs .nav-link.active {
    background-color: var(--main-color);
    color: var(--white-color);
}

#listing_area .home_six_section_heading h2::after {
    left: 0;
}

.new_listed_boxed {
    margin-top: 30px;
    border-radius: 17px;
    overflow: hidden;
    position: relative;
}

.top_ratings {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: var(--white-color);
    padding: 2px 14px;
    border-radius: 8px;
}

.top_ratings p span {
    color: var(--black-color);
}

.top_ratings i {
    color: var(--main-color);
}

.new_listed_boxed img {
    width: 100%;
    transition: var(--transition);
}

.new_listed_boxed:hover img {
    transform: scale(1.1);
}

.new_listed_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
}

.rating_outof {
    display: flex;
    align-items: center;
}

.rating_outof i {
    color: var(--white-color);
    font-size: 14px;
}

.new_listed_content h5 {
    color: var(--white-color);
    font-weight: 500;
}

.rating_outof h5 {
    color: var(--white-color);
    padding-left: 10px;
    font-weight: 300;
}

.new_listed_content h3 {
    color: var(--white-color);
    padding: 10px 0;
    font-weight: 500;
}

.new_listed_box_content {
    position: relative;
}

.new_listed_box_content::after {
    /* content: url(../img/icon/list-arrow.png); */
    position: absolute;
    right: 20px;
    bottom: 24px;
}



/* special offer area */

#special_offer {
    background-color: #F3F6FD;
}

.couple_tour h2 {
    padding-bottom: 24px;
}

.couple_tour p {
    padding-bottom: 24px;
    line-height: 32px;
}

.group_tour h2 {
    padding-top: 24px;
}

.group_tour p {
    padding-top: 24px;
    line-height: 32px;
}

/* top tour slider area */
#top_tour_area .home_six_section_heading h2::after {
    left: 0;
}


.common_card_six {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;
}

.common_card_six_img {
    overflow: hidden;
}

.common_card_six_img a img {
    width: 100%;
    transition: var(--transition);
}

.common_card_six:hover .common_card_six_img a img {
    transform: scale(1.1);
}

.common_card_six_text {
    padding: 20px 10px;
}

.common_card_six_list li {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #727178;
    align-items: center;
}

.common_card_six_list li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px;
}

.common_card_six_text h4 {
    padding-top: 5px;
}

.common_card_six:hover .common_card_six_text h4 a {
    color: var(--main-color);
}

.common_card_six_text p {
    font-size: 14px;
    padding-top: 5px;
}

.common_card_six_text p i {
    font-size: 14px;
    padding-right: 5px;
}

.common_card_six_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 12px;
}


.common_card_six_bottom_left p {
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
}

.common_card_six_bottom_left h6 {
    color: #727178;
    font-size: 12px;
    padding-top: 3px;
}

.common_card_six_bottom_right h4 sub {
    font-size: 12px;
}

.common_card_six_text h4 a {
    color: var(--black-color);
    transition: var(--transition);
    font-weight: 400;
}

/* testimonial area */
#testimonial-area {
    background-color: #F3F6FD;
}

.testimonial-card {
    padding: 0px 20px;
}

.testimonial-text p {
    font-size: 24px;
    line-height: 38px;
}

.testimonial-img {
    height: 150px;
    width: 150px;
    margin: auto;
    padding-bottom: 36px;
}

.author-info h3 {
    font-weight: 500;
}

.author-info p {
    font-size: 16px;
}

.author-info {
    padding: 16px 0;
}

.slider_btn .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: var(--main-color);
}

.slider_btn .owl-theme .owl-dots .owl-dot.active span {
    width: 26px;
    height: 8px;
}

.slider_btn .owl-theme .owl-dots .owl-dot span {
    width: 20px;
    height: 8px;
    margin: 10px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 10px;
}

.testimonial-text {
    position: relative;
}

.testimonial-text::before {
    position: absolute;
    /* content: url(../img/icon/testimonial-quate.png); */
    left: -20px;
    bottom: 58px;
}

/* home six news area */
#home_six_news_area .home_six_section_heading h2::after {
    left: 0;
}

.news_card_six {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;
}

.news_card_six_img {
    overflow: hidden;
}

.news_card_six_img a img {
    width: 100%;
    transition: var(--transition);
}

.news_card_six:hover .news_card_six_img a img {
    transform: scale(1.1);
}

.news_card_six_text {
    padding: 30px 20px;
    position: relative;
}

.news_card_six_list li {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #727178;
    align-items: center;
}

.news_card_six_list li i {
    font-size: 7px;
    color: #727178;
    padding-left: 7px;
    padding-right: 5px;
}

.news_card_six_text h3 {
    padding-top: 16px;
    padding-bottom: 24px;
}

.news_card_six_text h3 a {
    font-size: 24px;
    color: var(--black-color);
    font-weight: 500;
    line-height: 33px;
}

.news_card_six:hover .news_card_six_text h3 a {
    color: var(--main-color);
}


.news_card_six_text a i {
    margin-left: 8px;
}

.news_card_six_top {
    background-color: var(--main-color);
    color: var(--white-color);
    display: inline-block;
    padding: 10px 20px;
    border-radius: 6px;
}

.news_card_six_top h3 {
    padding: 0;
    font-weight: 500;
}

.news_card_six_top {
    position: absolute;
    top: -55px;
    right: 37px;
    z-index: 999;
    text-align: center;
}


/*==============================
 homepage seven area
 =============================== */

/* banner area */
#home_seven_banner {
    /* background-image: url("../img/home-seven/common/banner-bg.jpg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 160px 0px 300px 0px;
}

.banner_seven_text {
    color: var(--white-color);
    text-align: center;
    margin: auto;

}

.banner_seven_text h1 {
    text-transform: uppercase;
    padding-bottom: 12px;
    font-size: 96px;
    line-height: 120px;
    font-weight: 500;
}

.banner_seven_text p {
    color: var(--white-color);
    width: 70%;
    margin: auto;
}

.home_seven_video_area {
    position: absolute;
    bottom: 30%;
    left: 48%;
}


.home_seven_video_play_area i {
    color: #c7b9b9;
    position: relative;
    z-index: 99;
    font-size: 18px;
}


.home_seven_video_play_area:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: #c7b9b9;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.home_seven_video_play_area:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--main-color);
    border-radius: 50%;
    transition: all 200ms;
}

/* theam form area */

/* why choose us section */
.section_heading_seven {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section_heading_seven a {
    color: var(--black-color);
}

.section_heading_seven a:hover {
    color: var(--main-color);
}

.section_heading_seven h2 {
    padding-bottom: 12px;
    position: relative;
}

.section_heading_seven h2::after {
    position: absolute;
    content: " ";
    height: 2px;
    width: 80px;
    background-color: var(--main-color);
    bottom: 0;
    left: 0;

}


.why_choose_andtour {
    padding-top: 30px;
}

.choose_img {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;

}

.why_choose_andtour_card h3 {
    font-weight: 500;
    padding: 20px 0px 10px 0px;
}

.choose_img img {
    transition: var(--transition);
}

.why_choose_andtour_card:hover .choose_img img {
    transform: translateY(5px);
}

/* trending destination */
.trending_item {
    text-align: center;
}

.trending_item h3 {
    font-weight: 500;
    padding: 20px 0px 4px 0px;
}

.trending_destinations_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
}

.trending_item:hover .treding_img img {
    transform: scale(1.2);
}

.treding_img img {
    transition: var(--transition);
}

.treding_img {
    overflow: hidden;
    border-radius: 50%;
}

/* popular tour area */

.common_card_seven {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;
}

.common_card_seven_img {
    overflow: hidden;
}

.common_card_seven_img a img {
    width: 100%;
    transition: var(--transition);
}

.common_card_seven:hover .common_card_seven_img a img {
    transform: scale(1.1);
}

.common_card_seven_text {
    padding: 20px 10px;
}

.common_card_seven_text h3 {
    padding-top: 5px;
}

.common_card_seven_text h3 a {
    font-size: 20px;
    color: var(--black-color);
    font-weight: 400;
}

.common_card_seven:hover .common_card_seven_text h3 a {
    color: var(--main-color);
}

.common_card_seven_text p {
    font-size: 14px;
    padding-top: 5px;
}

.common_card_seven_text p i {
    font-size: 14px;
    padding-right: 5px;
}

.common_card_seven_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 12px;
}

.common_card_seven_bottom_left p {
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
}

.common_card_seven_bottom_left h6 {
    color: #727178;
    font-size: 12px;
    padding-top: 3px;
}

.common_card_seven_bottom_right h4 sub {
    font-size: 12px;
}


/* discount area */

.discount_area_wrapper {
    /* background-image: url("../img/home-seven/common/discount-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
}

.discount_img img {
    width: 100%;
    transition: var(--transition);
}

.discount_img:hover img {
    transform: scale(1.1);
}

.discount_img {
    overflow: hidden;
    border-radius: 12px;
}

.discount_left_area {
    padding: 0px 60px;
}

.discount_left_area h2 {
    line-height: 45px;
    padding-bottom: 16px;
}

.discount_left_area p {
    padding-bottom: 24px;
}

/* amazing tour area */

.amazing_tour_box {
    margin-top: 30px;
    border-radius: 17px;
    overflow: hidden;
    position: relative;
}

.top_ratings {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: var(--white-color);
    padding: 2px 14px;
    border-radius: 8px;
}

.top_ratings p span {
    color: var(--black-color);
}

.top_ratings i {
    color: var(--main-color);
}

.amazing_tour_box img {
    width: 100%;
    transition: var(--transition);
}

.amazing_tour_box:hover img {
    transform: scale(1.1);
}

.amazing_tour_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
}

.rating_outof {
    display: flex;
    align-items: center;
}

.rating_outof i {
    color: var(--white-color);
    font-size: 14px;
}

.new_listed_content h5 {
    color: var(--white-color);
    font-weight: 500;
}

.rating_outof h5 {
    color: var(--white-color);
    padding-left: 10px;
    font-weight: 300;
}

.amazing_tour_content h3 {
    color: var(--white-color);
    padding: 10px 0;
    font-weight: 500;
}

.amazing_tour_box_content {
    position: relative;
}

.amazing_tour_box_content::after {
    /* content: url(../img/icon/list-arrow.png); */
    position: absolute;
    right: 20px;
    bottom: 24px;
}

.amazing_tour_content h5 {
    color: var(--white-color);
}


.amazing_end_area .amazing_tour_box .amazing_tour_box_content::after {
    content: " ";
}

/* client feedback area */
#client_feedback {
    /* background-image: url("../img/home-seven/common/client-bg.png"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px 200px 0px;
}

.client_feedback_slider {
    margin-top: 30px;
}

.client_feedback_top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.client_feedback_bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
}

.client_feedback-card {
    padding: 26px 20px;
    background-color: var(--white-color);
    border-radius: 8px;
}

.client_feedback_bottom h3 {
    font-weight: 500;
}

.client_feedback_bottom p {
    font-size: 14px;
}

.client_ratings p {
    color: var(--main-color);
}

.client_ratings .all_rating i {
    font-size: 14px;
    color: var(--main-color);
}

/* counter area seven */
.counter_seven_area_wrapper {
    background: #FFFFFF;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.06), 8px 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: -100px;
}

.countre_seven_item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 74px 46px;
}

.countre_seven_item h3 {
    font-size: 48px;
    font-weight: 500;
}

/* home seven news abd blog area */

.home_seven_news_box {
    margin-top: 30px;
    border-radius: 17px;
    overflow: hidden;
    position: relative;
}

.home_seven_news_box img {
    width: 100%;
    transition: var(--transition);
}

.home_seven_news_box:hover img {
    transform: scale(1.1);
}

.home_seven_news_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
}

.news_content_top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3%;
    padding-bottom: 10px;
}

.news_admin ::before {
    position: absolute;
    content: " ";
    height: 30px;
    background-color: #999898;
    width: 1px;
    left: 0;
    top: -4px;
}

.news_admin {
    padding-left: 20px;
    position: relative;
}

.news_date,
.news_admin {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 5px;
    position: relative;
}

.news_date p,
.news_admin p {
    color: var(--white-color);
    font-size: 14px;
    font-weight: 300;
}

.news_date p span,
.news_admin p span {
    font-size: 16px;
    font-weight: 500;
    padding-right: 2px;
}

.news_admin img,
.news_date img {
    width: 16px;
    height: 15px;
}

.home_seven_news_content h3 a {
    color: var(--white-color);
    font-weight: 500;
}

.home_seven_news_content h3 {
    padding-bottom: 12px;
}

.home_seven_news_content p {
    color: var(--white-color);
    font-weight: 300;
}

.home_seven_news_content p a {
    color: var(--white-color);
}

.home_seven_news_content p a:hover {
    color: var(--main-color);
}

/*==========================
 homepage eight
======================== */
/* banner area eight */

#home_eight_banner {
    /* background-image: url("../img/home-eight/common/banner-bg-8.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}

.banner_eight_text {
    text-align: center;
}

.banner_eight_text p {
    color: var(--black-color);
    width: 70%;
    margin: auto;
}

.banner_eight_text h1 {
    padding-top: 70px;
    font-size: 64px;
    font-weight: 500;
    line-height: 82px;
    padding-bottom: 16px;
}

/* theme form area */
#theme_search_form_eight {
    margin-top: -40px;
    z-index: 9;
}

/* home eight card area */
.home_eight_card {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: var(--transition);
    height: 250px;
    border-radius: 12px;
}

.card_one {
    /* background-image: url("../img/home-eight/card/card-1.png"); */
}

.card_two {
    /* background-image: url("../img/home-eight/card/card-2.png"); */
}

.card_three {
    /* background-image: url("../img/home-eight/card/card-3.png"); */
}

.home_eight_card_text {
    padding: 60px 0px 60px 30px;
    width: 50%;
}

.home_eight_card:hover {
    transform: translateY(10px);
}

.home_eight_card a {
    color: var(--white-color);
}

.home_eight_card_text p {
    color: var(--white-color);
}

.home_eight_card_text h2 {
    padding: 6px 0;
}

/* popular destination area */
#popular_destination_eight {
    /* background-image: url("../img/home-eight/common/popular-de-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.section_heading_eight {
    padding-bottom: 30px;
}

.section_heading_eight h5 {
    color: var(--main-color);
    padding-bottom: 16px;
}

.section_heading_eight h2 {
    position: relative;
    padding-bottom: 10px;
}

.section_heading_eight h2::after {
    position: absolute;
    content: " ";
    background-color: var(--main-color);
    height: 2px;
    width: 80px;
    bottom: 0;
    left: 0;
}

.common_card_eight {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
}

.common_card_eight_img {
    overflow: hidden;
}

.common_card_eight_img a img {
    width: 100%;
    transition: var(--transition);
}

.common_card_eight:hover .common_card_eight_img a img {
    transform: scale(1.1);
}

.common_card_eight_text {
    padding: 20px 10px;
    position: relative;
}

.common_card_eight_list li {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #727178;
    align-items: center;
}

.common_card_eight_list li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px;
}

.common_card_eight_text h3 {
    padding-top: 5px;
}

.common_card_eight_text h3 a {
    font-size: 20px;
    color: var(--black-color);
    font-weight: 400;
}

.common_card_eight:hover .common_card_eight_text h3 a {
    color: var(--main-color);
}

.common_card_eight_text p {
    font-size: 14px;
    padding-top: 5px;
}

.common_card_eight_text p i {
    font-size: 14px;
    padding-right: 5px;
}

.common_card_eight_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 12px;
}

.common_card_eight_bottom_left p {
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
}

.common_card_eight_bottom_left h6 {
    color: #727178;
    font-size: 12px;
    padding-top: 3px;
}

.common_card_eight_bottom_right h4 sub {
    font-size: 12px;
}

/* amazing place area */


.amazing_place_boxed {
    margin-bottom: 30px;
    border-radius: 17px;
    overflow: hidden;
    position: relative;
}

.padding_img {
    margin-bottom: -24px;
}

.amazing_place_img img {
    width: 100%;
    transition: var(--transition);
}

.amazing_place_boxed:hover img {
    transform: scale(1.1);
}

.amazing_place_boxed:hover .amazing_place_box_content {
    opacity: 1;
}

.amazing_place_box_content {
    background: linear-gradient(359deg, #111018 0%, rgb(217 217 217 / 0%) 70%);
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
}

.amazing_place_inner_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
}

.rating_outof {
    display: flex;
    align-items: center;
}

.rating_outof i {
    color: var(--white-color);
    font-size: 14px;
}

.rating_outof h5 {
    color: var(--white-color);
    padding-left: 10px;
    font-weight: 300;
}

.amazing_place_inner_content h3 {
    color: var(--white-color);
    padding: 10px 0;
    font-weight: 400;
}

.amazing_place_inner_content h4 {
    color: var(--white-color);
    font-weight: 400;
}

.amazing_place_inner_content h5 {
    color: var(--white-color);
    font-weight: 400;
}

.amazing_place_inner_content p {
    color: var(--white-color);
    padding-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}



/* home eight service area */
#home_eight_service_area {
    /* background-image: url("../img/home-eight/common/service-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home_eight_service_boxed {
    margin-bottom: 40px;
}

.home_eight_service_boxed h3 {
    font-weight: 500;
    padding: 20px 0px 16px 0px;
}

.home_eight_service_boxed img {
    background-color: #fff;
    padding: 12px;
    box-shadow: 8px 10px 20px rgba(0, 0, 0, 0.06), 8px 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    transition: var(--transition);
}

.home_eight_service_boxed:hover img {
    transform: translateY(5px);
}

/* home eight destination */
.previous-price {
    font-size: 14px;
    text-decoration: line-through;
}

.discount_text p a {
    color: var(--main-color);
    font-weight: 500;

    line-height: 20px;
    transition: var(--transition);
    font-size: 16px;
}

.discount_text p {
    padding: 10px;
    line-height: 20px;
}

.discount_text p span {
    font-size: 13px;
    font-weight: 400;
}

.discount_text {
    position: absolute;
    width: 60px;
    height: 60px;
    right: 20px;
    top: -25px;
    background-color: var(--white-color);
    border: 1px solid var(--main-color);
    text-align: center;
    border-radius: 4px;
    transition: var(--transition);
}

.common_card_eight:hover .discount_text {
    background-color: var(--main-color);
}

.common_card_eight:hover .discount_text p a {
    color: var(--white-color);
}

#home_eight_destination .common_card_eight {
    margin-bottom: 30px;
}

.comnon_card_eight_bottom_second {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.common_card_eight_text .common-para {
    font-size: 16px;
}

.common_card_eight_text .common-para i {
    font-size: 18px;
}

.comnon_card_eight_bottom_second .common_card_eight_bottom_right sub {
    font-size: 14px;
}

/* --Our Partner-- */
#our_partners_eight {
    background-color: #F3F6FD;
}

#our_partners_eight .owl-carousel .owl-item img {
    width: auto !important;
    filter: opacity(0.5);
}

#our_partners_eight .owl-carousel .owl-item img:hover {
    filter: opacity(1);
}

/* team area */

.team-card {
    position: relative;
    box-shadow: 8px 10px 24px rgba(0, 0, 0, 0.1);
}

.team-card:hover .team-img img {
    transform: scale(1.1);
}

.team-img {
    overflow: hidden;

}

.team-img img {
    transition: var(--transition);
}


.team-img img {
    width: 100%;
    height: auto;
}

.team-info {
    position: absolute;
    bottom: 23px;
}

.team-info h3 {
    background-color: #fff;
    margin-bottom: 6px;
    font-weight: 500;
    padding: 6px 12px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.team-info h3 a {
    color: var(--black-color);
}

.team-info h3 a:hover {
    color: var(--main-color);
}

.team-info p {
    background-color: #fff;
    margin: 4px 0;
    padding: 2px 8px;
    font-weight: 500;
    display: inline-block;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.social-icon-area {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 0%;
    transition: var(--transition);
}

.social-icon-area:hover {
    height: 100%;
}

.social-icon-area ul li a {
    color: var(--heading-color);
    font-size: 22px;
    transition: var(--transition);
}

.social-icon-area ul li a:hover {
    color: var(--theme-color);
}

.invisible-icon {
    background-color: #fff;
    padding: 4px 10px;
    height: 38%;
    opacity: 0;
    transition: 0.3s all ease-in-out;
    transform: translate(0%, 2%);
}

.social-icon-area:hover .invisible-icon {
    opacity: 1;
}

.invisible-icon li :hover {
    color: var(--main-color);
}

.invisible-icon li i {
    transition: var(--transition);
}

.visible-icon li a img {
    margin-bottom: 2px;
}

/*====================================
 homepage  nine area 
 =================================== */
#home_nine_banner {
    /* background-image: url("../img/home-nine/common/banner-nine.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 240px 0px 310px 0px;
}

.banner_nine_text {
    text-align: center;
    color: var(--white-color);
}

.banner_nine_text h3 {
    font-weight: 500;
}

.banner_nine_text h1 {
    font-size: 190px;
    line-height: 150px;
    font-weight: bold;
    padding: 20px 0;
}

.banner_nine_text p {
    color: var(--white-color);
    padding: 0px 80px;
    line-height: 30px;
}

/* best offer section */
.best_offer_boxed {
    margin-bottom: 30px;
    border-radius: 17px;
    overflow: hidden;
    position: relative;
}


.best_offer_img img {
    width: 100%;
    transition: var(--transition);
}

.best_offer_boxed:hover img {
    transform: scale(1.1);
}

.best_offer_box_content {
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: var(--transition);
}

.best_offer_inner_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px 20px;
}

.best_offer_inner_content h3 {
    color: var(--white-color);
    padding: 10px 0;
    font-weight: 400;
}

.best_offer_inner_content h5 {
    color: var(--white-color);
    font-weight: 400;
}

.best_offer_inner_content p {
    color: var(--white-color);
    padding-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.best_offer_box_bottom {
    display: flex;
    justify-content: space-between;
}

.best_offer_bottom_right {
    position: absolute;
    bottom: -14px;
    right: 0;
    padding: 30px 20px;
    color: var(--white-color);
}

.best_offer_bottom_right h2 {
    line-height: 34px;
    font-weight: 500;
}

.best_offer_bottom_right p {
    color: var(--white-color);
    font-weight: 500;
}

/* tour category section */
.tour_category_nine_card {
    text-align: center;
    background-color: var(--white-color);
    border: 1px solid #F1F1F1;
    margin-top: 80px;
    box-shadow: 10px 16px 24px #F1F1F1;
    padding: 28px;
    border-radius: 12px;
    transition: var(--transition);
}

.tour_category_nine_card:hover {
    background-color: var(--bg-color);
}

.tour_category_nine_card:hover .tour_nine_img img {
    transform: translateY(10px);
}

.tour_nine_img img {
    margin-top: -100px;
    transition: var(--transition);
}

.tour_nine_img {
    margin-bottom: 24px;
}

.tour_category_nine_card h3 {
    font-weight: 500;
    padding-bottom: 12px;
}

.tour_category_nine_card .btn_md {
    padding: 8px 30px;
    margin-top: 16px;
}

/* subcribe area */
#subcribe_area_nine .form-control {
    border: none;
}

.subcribe_form_area {
    background-color: var(--bg-color);
    padding: 125px 119px 125px 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.subcribe_form_area h2 {
    font-weight: 500;
    padding-bottom: 20px;
}

.subcribe_form_area p {
    padding-bottom: 28px;
}

.subcribe_img {
    position: relative;
}

.subcribe_img img {
    width: 100%;
}

.subcribe_video_area {
    position: absolute;
    top: 48%;
    left: 49%;
}


#subcribe_area_nine .video_play_area i {
    color: #A96B82;
    position: relative;
    z-index: 99;
    font-size: 18px;
}


#subcribe_area_nine .video_play_area:before {
    width: 60px;
    height: 60px;
    background: var(--main-color);
}

#subcribe_area_nine .video_play_area:after {
    background: var(--white-color);
}


/* new and blog area */
.news_card_nine_heading ul li {
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    color: #727178;
    align-items: center;
}

.news_card_nine_heading ul li i {
    font-size: 7px;
    color: var(--main-color);
    padding-left: 7px;
    padding-right: 5px;
}

.news_card_nine_img {
    overflow: hidden;
    border-radius: 8px;
}

.news_card_nine_img a img {
    width: 100%;
    transition: var(--transition);
}

.news_card_nine_wrapper:hover .news_card_nine_img a img {
    transform: scale(1.1);
}

.news_card_nine_heading {
    padding-top: 20px;
}

.news_card_nine_heading h3 {
    font-weight: 600;
    padding-bottom: 10px;
}

.news_card_nine_heading h3 a {
    color: var(--black-color);
}

.news_card_nine_wrapper:hover .news_card_nine_heading h3 a {
    color: var(--main-color);
}

/* ===========================
 homepage ten area 
============================== */
/* banner area */
.banner_ten_text {
    width: 60%;
    margin: auto;
    text-align: center;
    color: var(--white-color);
}

.banner_ten_text h1 {
    font-size: 64px;
    font-weight: 500;
    padding-bottom: 20px;
}

.banner_ten_text p {
    color: var(--white-color);
}

.banner_ten_slider_item {
    padding: 230px 0px;
    /* background-image: url("../img/home-ten/common/banner-ten.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    width: 90%;
    margin: auto;
}

.banner_ten_button_style.owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    left: 47%;
    bottom: 24%;
}

.banner_ten_button_style.owl-carousel .owl-nav button.owl-next,
.banner_ten_button_style.owl-carousel .owl-nav button.owl-prev {
    background: var(--white-color);
    color: var(--black-color);
    border: none;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}


.banner_ten_button_style.owl-carousel .owl-nav button.owl-next:hover,
.banner_ten_button_style.owl-carousel .owl-nav button.owl-prev:hover {
    background: var(--main-color);
    color: var(--white-color);
}


.theme_search_form_two {
    width: 90%;
    margin: auto;
}

/* visa service area */
.visa_service_card_wrapper .visa_card_text .btn_md {
    padding: 8px 30px;
}

.visa_service_card_wrapper {
    background-color: var(--white-color);
    border: 1px solid #F1F1F1;
    box-shadow: 10px 16px 24px #F1F1F1;
    padding-bottom: 28px;
    border-radius: 12px;
}

.visa_service_card_wrapper:hover .visa_card_img img {
    transform: scale(1.1);
}

.visa_service_card_wrapper:hover .visa_card_icon img {
    transform: translateY(15px);
}

.visa_card_img {
    overflow: hidden;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

}

.visa_card_img img {
    width: 100%;
    height: auto;
    transition: var(--transition);
}

.visa_card_icon img {
    margin-top: -30px;
    position: relative;
    z-index: 9;
    transition: var(--transition);
}

.visa_card_text {
    text-align: center;
    width: 70%;
    margin: auto;
}

.visa_card_text h3 {
    font-weight: 500;
    padding-bottom: 12px;
}

.visa_card_text p {
    padding-bottom: 16px;
}

/* home ten about area */

.home_ten_about_left {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}
.home_ten_about_left:hover img {
    transform: scale(1.1);
}

.home_ten_about_left img {
    width: 100%;
    transition: var(--transition);
}

.about_ten_content {
    padding-top: 26px;
}

.home_ten_about_right .home_ten_section_heading h2::after {
    left: 0;
}

.about_ten_content p {
    line-height: 32px;
}

.about_ten_list_item {
    display: flex;
    align-items: center;
    gap: 100px;
}

.about_ten_list_item ul li {
    padding-top: 20px;
}

.about_ten_list_item ul li img {
    margin-right: 8px;
}

.about_ten_para {
    padding: 20px 0px 38px 0px;
    border-bottom: 2px dashed var(--main-color);
}

.about_ten_about_author {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 100px;
    padding-top: 40px;
    flex-wrap: wrap;
}

.home_ten_video_area {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--main-color);
    padding: 60px 90px;
    border-bottom-left-radius: 12px;
}

.home_ten_video_play_area i {
    color: var(--main-color);
    position: relative;
    z-index: 99;
    font-size: 18px;
}


.home_ten_video_play_area:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.home_ten_video_play_area:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    transition: all 200ms;
}

.home_ten_section_heading h5 {
    display: inline-block;
    color: var(--main-color);
    margin-bottom: 18px;
}

.home_ten_section_heading h2 {
    position: relative;
    padding-bottom: 12px;
    line-height: 48px;
}


.home_ten_section_heading h2::after {
    position: absolute;
    background-color: var(--main-color);
    height: 2px;
    width: 100px;
    content: " ";
    bottom: 0;
    left: 0%;
}

/* question area */
#question_ten_area {
    margin-bottom: -120px;
    position: relative;
    z-index: 9;
}

.question_ten_wrapper {
    background-color: var(--bg-color);
    border-radius: 12px;
}

.question_img img {
    width: 100%;
}

.question_ten_text {
    padding-left: 40px;
}

.question_ten_text h2 {
    padding-bottom: 12px;
    color: var(--black-color);
}

.question_ten_text h3 {
    font-weight: 500;

}

.question_ten_text h3 img {
    padding-top: 12px;

}

/* desired_country_area */
#desired_country_area {
    /* background-image: url("../img/home-ten/common/country-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 220px 0px 100px 0px;
}

.desired_country_icon {
    height: 90px;
    width: 100px;
    padding: 0 !important;
    margin: auto;
    margin-top: -40px;
    position: relative;
    z-index: 9;
}

.desired_country_wrapper {
    background-color: var(--white-color);
    border: 1px solid #F1F1F1;
    box-shadow: 10px 16px 24px #F1F1F1;
    padding-bottom: 28px;
    border-radius: 12px;
}

.desired_country_wrapper:hover .desired_country_img img{
transform: scale(1.1);
}
.desired_country_img img{
  transition: var(--transition); 
  width: 100%; 
}
.desired_country_img{
overflow: hidden;
border-top-right-radius: 12px;
border-top-left-radius: 12px;
}

.desired_country_text {
    text-align: center;
    padding: 0px 20px;
}

.desired_country_text h3 {
    font-weight: 500;
    padding-bottom: 12px;
}
/* home ten team area */
#home_ten_team_area{
    padding: 100px 0px 160px 0px;
}

/* check form area */
#check_form_area {
    /* background-image: url("../img/home-ten/common/check-form-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#check_form_area .btn {
    display: block;
}

.check_form_img {
    margin-top: -80px;
    text-align: end;
}

.check_form_head {
    width: 80%;
}

.check_form_head h2 {
    font-size: 32px;
    padding-bottom: 16px;
}

.check-btn {
    background-color: var(--main-color);
    color: var(--white-color);
    transition: var(--transition);
}

.check-btn:hover {
    background-color: var(--black-color);
    color: var(--white-color);
}
/* .immigration_card area */
.immigration_card_wrapper {
    background-color: var(--white-color);
    border: 1px solid #F1F1F1;
    box-shadow: 10px 16px 24px #F1F1F1;
    padding-bottom: 28px;
    border-radius: 12px;
}
.immigration_card_wrapper:hover .immigration_card_img img{
    transform: scale(1.1);
}
.immigration_card_img {
    overflow: hidden;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}
.immigration_card_img img{
    transition: var(--transition);
    width: 100%;
}
.immigration_text {
    text-align: center;
    padding: 0px 26px;
}

.immigration_card_price h3 {
    font-weight: 500;
    padding-top: 24px;
}

.immigration_card_price {
    position: relative;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: auto;
    margin-top: -40px;
    z-index: 9;
    box-shadow: 10px 16px 24px #F1F1F1;
    margin-bottom: 12px;
}

.immigration_text h3 {
    font-weight: 500;
    padding-bottom: 12px;
}

/* home ten news area */
.news_card_ten {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;
    box-shadow: 10px 16px 24px #F1F1F1;
}

.news_card_ten_img {
    overflow: hidden;
}

.news_card_ten_img a img {
    width: 100%;
    transition: var(--transition);
}

.news_card_ten:hover .news_card_ten_img a img {
    transform: scale(1.1);
}

.news_card_ten_text {
    padding: 30px 20px;
    position: relative;
}

.news_card_ten_list li {
    display: inline-flex;
    font-size: 16px;
    font-weight: 400;
    color: #727178;
    align-items: center;
    transition: var(--transition);
}
.news_card_ten_list li a{
    color: #727178;
}
.news_card_ten_list li .active{
    color: var(--main-color);
    transition: var(--transition);
}
.news_card_ten_list li .active:hover{
    color: #727178;
}

.news_card_ten_list li i {
    font-size: 7px;
    color: #727178;
    padding-left: 7px;
    padding-right: 5px;
}

.news_card_ten_text h3 {
    padding-top: 16px;
    padding-bottom: 24px;
}

.news_card_ten_text h3 a {
    font-size: 24px;
    color: var(--black-color);
    font-weight: 500;
    line-height: 33px;
}

.news_card_ten:hover .news_card_ten_text h3 a {
    color: var(--main-color);
}


.news_card_ten_text a i {
    margin-left: 8px;
}

.news_card_ten_top h3 {
    padding: 0;
    font-weight: 500;
}

.news_card_ten_top {
    background-color: var(--main-color);
    color: var(--white-color);
    display: inline-block;
    padding: 10px 20px;
    border-radius: 6px;
    position: absolute;
    top: -55px;
    right: 37px;
    z-index: 9;
    text-align: center;
}

.news_ten_btn {
    position: relative;
    z-index: 9999;
    padding: 8px 4px;
    transition: var(--main-color);
}

.news_ten_btn::before {
    position: absolute;
    content: "";
    background-color: #E8D8FB;
    height: 100%;
    width: 20%;
    z-index: -1;
    left: -6px;
    bottom: 0;
    border-radius: 4px;
    transition: var(--transition);
}

.news_ten_btn:hover::before {
    width: 110%;
}
.news_ten_btn:hover {
    color: var(--main-color);
}

/* homepage eleven */
#home_eleven_banner{
    /* background-image: url("../img/home-eleven/common/banner-11.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 250px 0px 150px 0px;
}
.banner_eleven_text p{
    color: var(--white-color);
    padding-bottom: 24px;
    width: 90%;
}
.banner_eleven_text h1{
    color: var(--white-color);
    font-size: 96px;
    font-weight: 500;
    line-height: 110px;
    text-transform: uppercase;
}

#theme_search_form_eleven {
    margin-top: -80px;
    z-index: 9;
}

.home_eleven_video_area{
    position: relative;
    padding-right: 70px;
}
/* .home_eleven_video_area::before{
    content: "";
    position: absolute;
    background: url(../img/home-eleven/common/video-content.png);
    left: 0;
    top: 0;

} */
.home_eleven_video_area img{
position: absolute;
right: 0;
top: -70px;
}

.home_eleven_video_play_area i {
    color: #A099A9;
    position: relative;
    z-index: 99;
    font-size: 18px;
}

.home_eleven_video_play_area:after {
    content: "";
    position: absolute;
    z-index: 1;
    right: 30px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    transition: all 200ms;
}

.home_eleven_video_play_area:before {
    content: "";
    position: absolute;
    z-index: 0;
    right: 30px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

/* about eleven section */
#home_eleven_about_area{
    /* background-image: url("../img/home-eleven/common/about-eleven-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.home_eleven_about_left {
    overflow: hidden;
    border-radius: 20px;
}
.home_eleven_about_left:hover img {
    transform: scale(1.1);
}

.home_eleven_about_left img {
    width: 100%;
    transition: var(--transition);
}

.about_mission_vission_item{
    display: flex;
    padding-top: 20px;
}

.about_mission_area, .about_vission_area{
    display: flex;
}

.about_mission_icon, .about_vission_icon{
width: 60%;
}

.about_mission_text h3, .about_vission_text h3{
font-weight: 500;
color: var(--main-color);
padding-bottom: 6px;
}

.about_experience{
    display: flex;
    align-items: center;
    gap: 30px;
    padding-top: 20px;
}
.about_experience h3{
    font-weight: 500;
    padding-right: 61px;
}
.about_eleven_author {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 100px;
    padding-top: 30px;
    flex-wrap: wrap;
}
.about_eleven_para {
    padding: 20px 0px 28px 0px;
    border-bottom: 2px dashed var(--main-color);
}

/* home eight service area */
#home_eleven_service_area {
    /* background-image: url("../img/home-eight/common/service-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home_eleven_service_boxed {
    margin-bottom: 40px;
}

.home_eleven_service_boxed h3 {
    font-weight: 500;
    padding: 20px 0px 16px 0px;
}

.home_eleven_service_boxed img {
    background-color: #fff;
    padding: 12px;
    box-shadow: 8px 10px 20px rgba(0, 0, 0, 0.06), 8px 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    transition: var(--transition);
}

.home_eleven_service_boxed:hover img {
    transform: translateY(5px);
}

/* top tour slider area */

.common_card_eleven {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;
}

.common_card_eleven_img {
    overflow: hidden;
}

.common_card_eleven_img a img {
    width: 100%;
    transition: var(--transition);
}

.common_card_eleven:hover .common_card_eleven_img a img {
    transform: scale(1.1);
}

.common_card_eleven_text {
    padding: 20px 10px;
}

.common_card_eleven_list li {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #727178;
    align-items: center;
}

.common_card_eleven_list li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px;
}

.common_card_eleven_text h4 {
    padding-top: 5px;
}

.common_card_eleven:hover .common_card_eleven_text h4 a {
    color: var(--main-color);
}

.common_card_eleven_text p {
    font-size: 14px;
    padding-top: 5px;
}

.common_card_eleven_text p i {
    font-size: 14px;
    padding-right: 5px;
}

.common_card_eleven_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 12px;
}


.common_card_eleven_bottom_left p {
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
}

.common_card_eleven_bottom_left h6 {
    color: #727178;
    font-size: 12px;
    padding-top: 3px;
}

.common_card_eleven_bottom_right h4 sub {
    font-size: 12px;
}

.common_card_eleven_text h4 a {
    color: var(--black-color);
    transition: var(--transition);
    font-weight: 400;
}

.section_heading_eleven h5 {
    color: var(--main-color);
    padding-bottom: 16px;
}

.section_heading_eleven h2 {
    position: relative;
    padding-bottom: 16px;
    color: var(--black-color);
    line-height: 46px;
}

.section_heading_eleven h2::before {
    position: absolute;
    left: 47%;
    bottom: 0;
    content: "";
    background-color: var(--main-color);
    height: 2px;
    width: 80px;
}

/* home eleven gallery are */
.gallery_eleven_boxed {
    position: relative;
    border-radius: 14px;
    overflow: hidden;
    transition: var(--transition);
}

.gallery_eleven_boxed img {
    position: relative;
    width: 100%;
    transition: var(--transition);
}

.gallery_eleven_boxed:hover img {
    transform: scale(1.2);
}

.gallery_eleven_boxed:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 25%;
    left: 0;
    background: #17161669;
    bottom: 0;
    z-index: 999;
    transition: all 0.5s;
    transform: translate(0%, 100%);
}

.gallery_eleven_boxed:hover:before {
    transform: translate(0%, 0%);
}

.gallery_eleven_boxed h3 {
    position: absolute;
    bottom: 30px;
    left: 30px;
    justify-content: center;
    width: 100%;
    z-index: 999;
    align-items: center;
}


.gallery_eleven_boxed h3 a {
    color: var(--white-color);
    transition: var(--transition);
}

.gallery_eleven_boxed:hover h3 a {
    font-weight: 500;
}

.gallery_eleven_boxed_area{
    display: flex;
    gap: 30px;

}
.gallery_eleven_boxed{
    margin-bottom: 30px;
}
/* home eleven testimonial area  */
.testimonial_eleven_icon {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 36px;
  }
  
  .testimonial_eleven_text p {
    font-size: 24px;
    line-height: 40px;
    padding-bottom: 36px;
  }
  
  .testimonial_eleven_img {
    height: 150px;
    width: 150px;
    margin: auto;
    padding-bottom: 36px;
  }
  
  
  .author_eleven_info {
    padding-top: 30px;
  }
  
  .author_eleven_info h3 {
    line-height: 30px;
    font-weight: 500;
  }
  
  .slider-slide-btn .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 63%;
    right: 37%;
  }
  
  .slider-slide-btn .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 63%;
    left: 37%;
  }
  
  .slider-slide-btn .owl-theme .owl-nav [class*=owl-]:hover {
    background: transparent;
    color: var(--main-color);
    text-decoration: none;
  }
  
/* news eleven section */
.news_card_eleven_heading ul li {
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    color: #727178;
    align-items: center;
}

.news_card_eleven_heading ul li i {
    font-size: 7px;
    color: var(--main-color);
    padding-left: 7px;
    padding-right: 5px;
}

.news_card_eleven_img {
    overflow: hidden;
    border-radius: 8px;
}

.news_card_eleven_img a img {
    width: 100%;
    transition: var(--transition);
}

.news_card_eleven_wrapper:hover .news_card_eleven_img a img {
    transform: scale(1.1);
}

.news_card_eleven_heading {
    padding-top: 20px;
}

.news_card_eleven_heading h3 {
    font-weight: 600;
    padding-bottom: 10px;
}

.news_card_eleven_heading h3 a {
    color: var(--black-color);
}

.news_card_eleven_wrapper:hover .news_card_eleven_heading h3 a {
    color: var(--main-color);
}

/*==========================
 homepage twelve
 ===================== */
#banner_twelve{
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 280px 0px 300px 0px;
    text-align: center;
}

.video_banner{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.banner_twelve_text h1{
    font-size: 96px;
    line-height: 120px;
    color: var(--white-color);
    font-weight: 500;
}
.banner_twelve_text h1 span{
    font-size: 96px;
    font-weight: 200;
}

/* section heading twelve */
.section_heading_twelve {
    padding-bottom: 30px;
    position: relative;
}

.section_heading_twelve h5 {
    color: var(--main-color);
    padding-bottom: 16px;
}

.section_heading_twelve h2 {
    position: relative;
    padding-bottom: 16px;
    color: var(--black-color);
    line-height: 46px;
}

.section_heading_twelve h2::before {
    position: absolute;
    left: 47%;
    bottom: 0;
    content: "";
    background-color: var(--main-color);
    height: 2px;
    width: 80px;
}


/* travel service area */
.travael_serevice_twelve_wrapper {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.travael_serevice_twelve_wrapper img {
    width: 100%;
}

.travael_serevice_twelve_wrapper .travel_service_content {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 16px 20px;
    z-index: 9;
}


.travel_service_content h3 a{
    color: var(--white-color);
    font-weight: 500;
}
.travel_service_content h3{
    padding-bottom: 10px;
}

.travel_service_content p {
    color: var(--white-color);
    line-height: 22px;
}

/* partner slider area */
.our_partner_twelve{
    border-top: 1px solid #dfdfdf;
    padding-top: 60px;
    position: relative;
}

.our_partner_twelve .owl-theme .owl-nav {
    margin-top: 10px;
    display: block;
    position: absolute;
    top: -87px;
    left: 47%;
    background-color: var(--white-color);
    padding: 0px 8px;
}
.our_partner_twelve .owl-theme .owl-nav .owl-prev{
   width: 26px;
   background-color: #727178;
   color: var(--white-color);
}
.our_partner_twelve .owl-theme .owl-nav .owl-next{
   width: 26px;
   background-color: #727178;
   color: var(--white-color);
}

.our_partner_twelve .owl-theme .owl-nav [class*=owl-]:hover {
    background: var(--main-color);
    color: #FFF;
}


/* about twelve area  */
#home_twelve_about_area{
    /* background-image: url("../img/home-twelve/common/about-twelve-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0px 230px 0px;
}
.home_twelve_about_left {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}
.home_twelve_about_left:hover img {
    transform: scale(1.1);
}

.home_twelve_about_left img {
    width: 100%;
    transition: var(--transition);
}

.about_twelve_content {
    padding-top: 26px;
}

.home_twelve_about_right .home_twelve_section_heading h2::after {
    left: 0;
}

.about_twelve_content p {
    line-height: 32px;
}

.about_twelve_list_item {
    display: flex;
    align-items: center;
    gap: 100px;
}

.about_twelve_list_item ul li {
    padding-top: 20px;
}

.about_twelve_list_item ul li img {
    margin-right: 8px;
}

.about_twelve_para {
    padding: 20px 0px 38px 0px;
    border-bottom: 2px dashed var(--main-color);
}

.about_twelve_about_author {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 100px;
    padding-top: 40px;
    flex-wrap: wrap;
}

.home_twelve_video_area {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--main-color);
    padding: 60px 90px;
    border-bottom-left-radius: 12px;
}

.home_twelve_video_play_area i {
    color: var(--main-color);
    position: relative;
    z-index: 99;
    font-size: 18px;
}


.home_twelve_video_play_area:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.home_twelve_video_play_area:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    transition: all 200ms;
}

.home_twelve_section_heading h5 {
    display: inline-block;
    color: var(--main-color);
    margin-bottom: 18px;
}

.home_twelve_section_heading h2 {
    position: relative;
    padding-bottom: 12px;
    line-height: 48px;
}


.home_twelve_section_heading h2::after {
    position: absolute;
    background-color: var(--main-color);
    height: 2px;
    width: 100px;
    content: " ";
    bottom: 0;
    left: 0%;
}

/* counter area twelve  */
.counter_twelve_area_wrapper {
   /* background-image: url("../img/home-twelve/common/counter-twelve-bg.png"); */
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
    box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.06), 8px 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: -100px;
}

.countre_twelve_item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 74px 46px;
}

.countre_twelve_item h2 {
    font-size: 48px;
    font-weight: 500;
}

/* top country area */

.top_country_img{
    height: 90px;
    width: 90px;
    margin: auto;
    margin-bottom: 16px;
}
.top_country_text {
    text-align: center;
    /* margin: auto; */
}
.top_country_text a{
    color: var(--paragraph-color);
    font-size: 16px;
     padding: 8px 34px;
    background-color: var(--bg-color);
    display: inline-block;
    border-radius: 6px;
    transition: var(--transition);
}

.top_country_wrapper{
    padding: 20px 0;
    overflow: hidden;
    transition: var(--transition);
}

.top_country_wrapper:hover{
 background-color: var(--white-color); 
 /* box-shadow: 10px 16px 24px #F1F1F1;  */
 /* border: 1px solid #F1F1F1; */
 box-shadow: -4px -4px 16px rgba(0, 0, 0, 0.06), 4px 4px 16px rgba(0, 0, 0, 0.06);
 border-radius: 8px;
}
.top_country_wrapper:hover .top_country_text a{
    color: var(--white-color);
    background-color: var(--main-color);
}

.top_country_slider_wrapper{
    position: relative;
    overflow: hidden;
    padding: 0px 30px;
}

.country_slider_btn .owl-theme .owl-nav {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.country_slider_btn .owl-theme .owl-nav .owl-prev {
   position: absolute;
   left: 0;
   width: 24px;
   background-color: #727178;
   color: var(--white-color);
   top: 30%;
}
.country_slider_btn .owl-theme .owl-nav .owl-next {
   position: absolute;
   right: 0;
   width: 24px;
   background-color: #727178;
   color: var(--white-color);
   top: 30%;
}

.country_slider_btn .owl-theme .owl-nav [class*=owl-]:hover {
    background: var(--main-color);
    color: #FFF;
}

/* home twelve Faq section */
#home_twelve_faq_area{
    background-color: var(--bg-color);
    position: relative;
}

.faq_img_twelve img{
    position: absolute;
    left: 30px;
    top: 20px;
}

.faqs_main_item_twelve {
    margin-top: 30px;
    position: relative;
    z-index: 9;
}

.faqs_main_item_twelve .accordion-item_twelve {
    background-color: #fff;
    border: none;
    margin-bottom: 30px;
}

.faqs_main_item_twelve .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    color: var(--black-color);
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    font-weight: 500;
    box-shadow: none;
}

#home_twelve_faq_area .faqs_main_item_twelve .accordion-header {
    border: none;
    background-color: var(--white-color);
}

.faqs_main_item_twelve button:focus {
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
    border: none;
}
.faqs_main_item_twelve button{
    box-shadow: -4px -5px 14px rgba(0, 0, 0, 0.08), 5px 8px 16px rgba(0, 0, 0, 0.08);
    border: none;
}

.faqs_main_item_twelve .section_heading_twelve h2::before{
left: 0;
}

/* certification_twelve_area */
.certification_twelve_wrapper{
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.certification_twelve_content{
    position: absolute;
    left: 20px;
    bottom: 20px;
    right: 26px;
    transition: all 0.5s ease-in-out;
}
.certification_twelve_content h3 a{
   color: var(--white-color);
   font-weight: 500;
}

.trainer_head{
    display: flex;
    justify-content: space-between;
    z-index: 9;
    position: relative;
    transition: all 0.5s;

}

.certification_twelve_wrapper:hover:before {
    transform: translate(0%, 0%);
}

.certification_twelve_wrapper:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 35%;
    left: 0;
    background: var(--white-color);
    bottom: 0;
    z-index: 1;
    transition: all 0.5s;
    transform: translate(0%, 100%);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.trainer_img img{
    width: 100%;
    height: auto;
}

.certification_twelve_wrapper:hover .certification_twelve_content p{
display: block;
z-index: 999;
color: var(--black-color);
}
.certification_twelve_wrapper:hover .certification_twelve_content h3 a{
color: var(--black-color);
}
.certification_twelve_wrapper:hover .trainer_head img{
margin-top: -70px;
height: 30px;
width: 30px;
}

.trainer_head img{
    transition: all 0.5s ease-in-out;
}

.certification_twelve_content p{
    color: var(--black-color);
    display: none;
    z-index: 9;
    position: relative;
}

/* home twelve testimonial area */
#testimonial_twelve_area{
    background-color: var(--bg-color);
}

/* news twelve section */
.news_card_twelve_heading ul li {
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    color: #727178;
    align-items: center;
}

.news_card_twelve_heading ul li i {
    font-size: 7px;
    color: var(--main-color);
    padding-left: 7px;
    padding-right: 5px;
}

.news_card_twelve_img {
    overflow: hidden;
    border-radius: 8px;
}

.news_card_twelve_img a img {
    width: 100%;
    transition: var(--transition);
}

.news_card_twelve_wrapper:hover .news_card_twelve_img a img {
    transform: scale(1.1);
}

.news_card_twelve_heading {
    padding-top: 20px;
}

.news_card_twelve_heading h3 {
    font-weight: 600;
    padding-bottom: 10px;
}

.news_card_twelve_heading h3 a {
    color: var(--black-color);
}

.news_card_twelve_wrapper:hover .news_card_twelve_heading h3 a {
    color: var(--main-color);
}


/* .faqs_main_item_twelve .accordion-button::before{
    content: url(../img/icon/white-angle.png);
    position: absolute;
    background-color: var(--main-color);
    right: 0;
    top: 0;
    padding: 10px 20px;
}


.accordion-button:not(.collapsed)::before {
    content: url(../img/icon/white-angle.png);
    transform: rotate(-90deg);
    padding: 3px 19px;
}

.accordion-button::after {
   display: none;
} */

/*========================
 homepage thirteen 
 ============================*/
 #home_thirteen_banner{
    /* background-image: url("../img/home-thirteen/common/banner-thirteen.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 131px 0px 322px 0px;
    position: relative;
 }

 .banner_thirteen_text{
    /* padding: 70px 0px 40px 0px; */
 }

 .banner_thirteen_text h3{
    color: var(--white-color);
    font-weight: 500;
    margin-bottom: 160px;
    border: 1px solid var(--white-color);
    border-bottom: none;
    padding: 60px 0px 20px 0px;
 }
 .banner_thirteen_text h1 {
    position: absolute;
    font-size: 140px;
    font-weight: bold;
    line-height: 150px;
    color: var(--white-color);
    top: 30%;
    left: 22%;
    text-align: center;
}
 .banner_thirteen_text p{
    color: var(--white-color);
    border: 1px solid var(--white-color);
    border-top: none;
    padding-bottom: 44px;
 }
 /* section heading thirteen */
 .section_heading_thirteen{
   margin-bottom: 30px;
 }
 .section_heading_thirteen h2{
    position: relative;
    padding-bottom: 40px;
 }
 .section_heading_thirteen h2::after{
    position: absolute;
    /* content: url(../img/icon/section-thirteen.png); */
    bottom: 0;
    left: 43%;
 }

 /* tour packge area */
 .common_card_thirteen {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;
}

.common_card_thirteen_img {
    overflow: hidden;
}

.common_card_thirteen_img a img {
    width: 100%;
    transition: var(--transition);
}

.common_card_thirteen:hover .common_card_thirteen_img a img {
    transform: scale(1.1);
}

.common_card_thirteen_text {
    padding: 20px 10px;
    position: relative;
}

.common_card_thirteen_list li {
    display: inline-flex;
    font-size: 12px;
    font-weight: 400;
    color: #727178;
    align-items: center;
}

.common_card_thirteen_list li i {
    font-size: 7px;
    color: #DDDDDD;
    padding-left: 7px;
    padding-right: 5px;
}

.common_card_thirteen_text h3 {
    padding-top: 5px;
}

.common_card_thirteen_text h3 a {
    font-size: 20px;
    color: var(--black-color);
    font-weight: 400;
}

.common_card_thirteen:hover .common_card_thirteen_text h3 a {
    color: var(--main-color);
}

.common_card_thirteen_text p {
    font-size: 16px;
    padding-top: 5px;
}

.common_card_thirteen_text p i {
    font-size: 16px;
    padding-right: 5px;
}

.common_card_thirteen_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.common_card_thirteen_bottom_left p {
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
    padding-top: 0;
}

.common_card_thirteen_bottom_left h6 {
    color: #727178;
    font-size: 12px;
    padding-top: 3px;
}

.common_card_thirteen_bottom_right h4 sub {
    font-size: 12px;
}

.common_card_thirteen:hover .discount_text {
    background-color: var(--main-color);
}

.common_card_thirteen:hover .discount_text p a {
    color: var(--white-color);
}

.common_card_thirteen_text .discount_text p{
    padding-top: 10px;
}

/* D8DAD6 */
/* home thirteen clock area */

#discount_thirteen_area{
    /* background-image: url("../img/home-thirteen/common/clock-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 138px 0;
    background-attachment: fixed;
}

.discount_timer_text h3{
    color: var(--white-color);
    font-weight: 500;
}
.discount_timer_text h2{
    color: var(--white-color);
    font-weight: 600;
    padding: 24px 0;
}
.discount_timer_text p{
    color: var(--white-color);
    font-size: 18px;
    width: 75%;
    margin: auto;
}
.discount-clock-area {
   padding-top: 26px;
}
.discount-clock-area h3{
    font-weight: 500;
    color: var(--white-color);
}

.discount_timer_text{
    width: 80%;
    margin: auto;
}

.clock_area{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
    flex-wrap: wrap;
}
.round-clock{
    position: relative;
    z-index: 9;
    text-align: center;
    padding: 12px 16px;
    border: 1px solid var(--main-color);
    border-radius: 4px;
}
.round-clock h2{
   color: var(--black-color);
}
.round-clock p{
  color: var(--black-color); 
}
.round-clock::after{
    position: absolute;
    content: " ";
    height: 100%;
    width: 100%;
    background-color: #D8DAD6;
    opacity: .7;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 4px;
}

/* .discount_timer_text{
    border-bottom: 2px dashed #205681;
} */

/* destination thirteen section */
.destination_card_thirteen{
    position: relative;
}
.destination_thirteen_text{
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
}

.destination_thirteen_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--white-color);
    padding-bottom: 6px;
}
.destination_thirteen_head h2{
  color: var(--white-color);
  text-transform: uppercase;
}
.destination_thirteen_head h2 img{
  padding-bottom: 6px;
}

.destination_thirteen_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 6px;
}
.destination_thirteen_bottom p{
    color: var(--white-color);
}
.destination_thirteen_bottom a{
   color: var(--white-color);
}

/* .destination_card_thirteen:hover .destination_thirteen_bottom a{
    color: var(--main-color);
} */
.destination_card_thirteen-two{
    margin-bottom: 30px;
}

.off_text_thirteen{
    background-color: var(--main-color);
    padding: 4px 8px;
    position: absolute;
    right: 30px;
    top: 30px;
    border-radius: 4px;
}
.off_text_thirteen a{
    color: var(--white-color);

}

.destination_card_thirteen:hover .destination_thirteen_img img{
   transform: scale(1.1); 
}
.destination_thirteen_img img{
    transition: var(--transition);
    width: 100%;
    height: auto;
}
.destination_thirteen_img{
overflow: hidden;
border-radius: 12px;
}

/* counter thirteen  */
.counter_thirteen_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.counter_thirteen_area{
    text-align: center;
    border-right: 2px solid #dfdfdf;
    width: 100%;
}
.counter_thirteen_area:last-child{
    border-right: none;
}

.counter_thirteen_area img{
    margin-bottom: 16px;
}
.counter_thirteen_area h2{
   padding-bottom: 8px;
}
.counter_thirteen_area h3{
  font-weight: 400;
}

#counter_thirteen{
    background-color: var(--bg-color);
    padding: 50px 0;
}

/* watch_story_area_wrapper */
.watch_story_area_wrapper{
    /* background-image: url("../img/home-thirteen/common/video-thirteen-bg.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 146px 0px 130px 0px;
    border-radius: 12px;
}

.watch_story_area_wrapper h1{
font-size: 96px;
font-weight: 800;
line-height: 100px;
color: var(--white-color);
width: 60%;
text-align: end;
}
.watch_story_area_wrapper h1 span{
display: block;
color: transparent;
-webkit-text-stroke: 1.5px var(--white-color);
position: relative;
margin-right: -100px;
}
.watch_story_area_wrapper h1 span::before{
position: absolute;
content: "";
background-color: #FFFF;
height: 3px;
width: 250px;
bottom: 20px;
right: 275px;
opacity: .50;
}

.watch_story_video{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding-top: 40px;

}

.watch_story_video_area{
    position: relative;
}

.watch_story_video_play_area i {
    color: #877d99;
    position: relative;
    z-index: 99;
    font-size: 18px;
}


.watch_story_video_play_area:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--main-color);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.watch_story_video_play_area:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: var(--white-color);
    border-radius: 50%;
    transition: all 200ms;
}

/* testimonial thirteen */
/* home eleven testimonial area  */
.testimonial_thirteen_icon {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 36px;
  }
  
  .testimonial_thirteen_text p {
    font-size: 24px;
    line-height: 40px;
    padding-bottom: 36px;
  }
  
  .testimonial_thirteen_img {
    height: 150px;
    width: 150px;
    margin: auto;
    padding-bottom: 36px;
  }
  
  
  .author_thirteen_info {
    padding-top: 30px;
  }
  
  .author_thirteen_info h3 {
    line-height: 30px;
    font-weight: 500;
  }
  /* testimonial_thirten_area */
  #testimonial_thirten_area{
    background-color: var(--bg-color);
  }

  /* blog thirteen area */
  .news_card_thirteen {
    background: #FFFFFF;
    overflow: hidden;
}

.news_card_thirteen_img {
    overflow: hidden;
    border-radius: 12px;
}

.news_card_thirteen_img a img {
    width: 100%;
    transition: var(--transition);
}

.news_card_thirteen:hover .news_card_thirteen_img a img {
    transform: scale(1.1);
}

.news_card_thirteen_text {
    padding-top: 8px;
    position: relative;
}

.news_card_thirteen_list li a {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-color);
}
.news_card_thirteen_list li {
    display: inline-flex;
    align-items: center;
    padding-right: 12px;
    border-right: 1px solid #dfdfdf;
}
.news_card_thirteen_list li:last-child {
    border-right: none;
}

.news_card_thirteen_list li img {
    font-size: 7px;
    color: #727178;
    padding-left: 7px;
    padding-right: 5px;
}

.news_card_thirteen_text h3 {
    padding: 10px 0;
}

.news_card_thirteen_text h3 a {
    font-size: 24px;
    color: var(--black-color);
    font-weight: 500;
    line-height: 33px;
}

.news_card_thirteen:hover .news_card_thirteen_text h3 a {
    color: var(--main-color);
}


.news_card_thirteen_text a img {
    margin-left: 8px;
}

.news_card_thirteen_top {
    background-color: var(--main-color);
    color: var(--white-color);
    display: inline-block;
    padding: 16px 20px;
    border-radius: 6px;
}

.news_card_thirteen_top h3 {
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
}

.news_card_thirteen_top {
    position: absolute;
    top: -52px;
    right: 16px;
    z-index: 9;
    text-align: center;
}

 .blog-thirteen-btn{
    border: 1px solid var(--main-color);
    color: var(--main-color);
    font-weight: 500;
    margin-top: 16px;
    background-color: var(--white-color);
    transition: var(--transition);
 } 
 .blog-thirteen-btn:hover{
    background-color: var(--main-color);
 } 

 /*===========================
  homepage fourteen
  ========================== */
  /* banner */
  #home_fourteen_banner{
    /* background-image: url("../img/home-fourteen/common/banner-fourteen.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 170px 0px 280px 0px;
  }

  .banner_fourteen_text h1{
    font-size: 100px;
    line-height: 110px;
    font-weight: 800;
    color: var(--white-color);
  }

  .banner_fourteen_text h1 span{
    color: transparent;
    -webkit-text-stroke: 1.5px var(--white-color);
    display: block;
    position: relative;
  } 

  .banner_fourteen_text h1 span::after{
  position: absolute;
  content: "";
  right: 33%;
  bottom: 24px;
  height: 2px;
  width: 480px;
  background-color: var(--white-color);
  opacity: .50;
  } 

  .banner_fourteen_text p{
    color: var(--white-color);
    width: 70%;
    padding-top: 16px;
  }
  .banner_fourteen_text a{
    border: 1px solid var(--white-color);
    border-radius: 8px;
    color: var(--white-color);
    margin-top: 24px;
    transition: var(--transition);
  }

  .banner_fourteen_text a:hover{
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
  }

  /* countery card card area  */
  .country_card_box {
    overflow: hidden;
    position: relative;
}


.country_card_box img {
    width: 100%;
    transition: var(--transition);
}

.country_card_box:hover img {
    transform: scale(1.1);
}

.country_card_content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
}

.country_card_content h3{
  color: var(--white-color); 
  font-weight: 500; 
  line-height: 34px;
}
.country_card_content h5{
color: var(--white-color);
font-weight: 500;
line-height: 34px;
}
.country_card_content .rating_outof h5{
font-weight: 400;
}
/* home fourteen about area */
.home_fourteen_section_heading h5 {
    display: inline-block;
    color: var(--main-color);
    margin-bottom: 16px;
}

.home_fourteen_section_heading h3 {
    position: relative;
    padding-bottom: 12px;
    font-weight: 500;
}


.home_fourteen_section_heading h3::after {
    position: absolute;
    background-color: var(--main-color);
    height: 2px;
    width: 100px;
    content: " ";
    bottom: 0;
    left: 0%;
}

.about_fourteen_experience{
    padding-top: 8px;
}

.about_fourteen_experience h3{
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 16px;
}
.about_fourteen_content p{
    padding-top: 20px;
}
.about_fourteen_content .about_mission_vission_area p{
    padding-top: 0px;
}

.about_fourteen_right_img:hover img{
    transform: scale(1.1);
}

.about_fourteen_right_img img{
    transition: var(--transition);
    width: 100%;
}
.about_fourteen_right_img {
  overflow: hidden;
}

/* tour category fourteen area */
.tour_category_fourteen_card {
    text-align: center;
    background-color: var(--white-color);
    border: 1px solid #F1F1F1;
    margin-top: 80px;
    box-shadow: 10px 16px 24px #F1F1F1;
    padding: 28px;
    border-radius: 12px;
    transition: var(--transition);
}

.tour_category_fourteen_card:hover {
    background-color: var(--bg-color);
}

.tour_category_fourteen_card:hover .tour_fourteen_img img {
    transform: translateY(10px);
}

.tour_fourteen_img img {
    margin-top: -100px;
    transition: var(--transition);
}

.tour_fourteen_img {
    margin-bottom: 24px;
}

.tour_category_fourteen_card h3 {
    font-weight: 500;
    padding-bottom: 12px;
}

.tour_category_fourteen_card .btn_md {
    padding: 8px 30px;
    margin-top: 16px;
}

.tour_fourteen_img{
    width: 160px;
    height: 160px;
    margin: auto;
    margin-bottom: 16px;
}

.tour_category_fourteen_card a{
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.explore_arrow{
    height: 14px;
    width: 14px;
}

/* hotel booking area */
.hotel_booking_fourteen_wrapper {
    border-radius: 11px;
    overflow: hidden;
    border: 1px solid #d2d2d275;
    transition: var(--transition);
    margin-bottom: 30px;
}

.hotel_booking_fourteen_wrapper:hover {
    box-shadow: 4px 14px 28px rgba(0, 0, 0, 0.1);
}
.hotel_booking_fourteen_wrapper:hover .hotel_booking_content .discount_tab {
    background-color: var(--main-color);
    color: var(--white-color);
}

.hotel_booking_img {
    position: relative;
    overflow: hidden;
    z-index: -1;
}

.hotel_booking_img img {
    width: 100%;
}

.hotel_booking_content .discount_tab {
    position: absolute;
    right: 9px;
    top: -30px;
    background: #FFFFFF;
    box-shadow: 4px 5px 12px rgb(0 0 0 / 10%);
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 18px;
    border-radius: 4px;
    color: var(--main-color);
    font-size: 16px;
    font-weight: 500;
    z-index: 9;
    padding-top: 8px;
    border: 1px solid var(--main-color);
    transition: var(--transition);
}


.hotel_booking_img p {
    color: var(--white-color);
    position: absolute;
    bottom: 4px;
    left: 13px;
    font-size: 14px;
}

.hotel_booking_img p i {
    padding-right: 5px;
}

.hotel_booking_content {
    background: #fff;
    padding: 10px 15px;
    position: relative;
}

.hotel_booking_content h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.hotel_booking_content h4 a {
    color: var(--black-color);
}

.hotel_booking_content h4 a:hover {
    color: var(--main-color);
}

.hotel_booking_content p {
    padding-bottom: 10px;
    font-size: 14px;
}

.hotel_booking_content p .review_rating {
    color: var(--main-color);
}

.hotel_booking_content p .review_count {
    margin-left: 10px;
}

.hotel_booking_content h3 {
    font-weight: 500;
}

.hotel_booking_content h3 span {
    font-weight: 400;
    font-size: 14px;
}

.hotel_booking_rating {
   padding-top: 6px;
}
.hotel_booking_rating i{
    color: #F79837;
    font-size: 16px;
}

/* special_offer_fourteen_wrapper */
.special_offer_fourteen_wrapper{
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.special_offer_fourteen_text{
    position: absolute;
    bottom: 30px;
    left: 20px;
}
.special_offer_fourteen_text h2{
    color: var(--white-color);
    padding-bottom: 12px;
}
.special_offer_fourteen_text p{
  color: var(--white-color);
  padding-bottom: 40px;
}

.special_offer_fourteen_text p{
    width: 85%;
}

/* gallery area */
.gallery_item {
    position: relative;
    margin-bottom: 30px;
  }
  
  .gallery_item img {
    width: 100%;
  }
  
.gallery_overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #ff00003b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);
  }
  
  .gallery_item:hover .gallery_overlay {
    opacity: 1;
    visibility: visible;
  }
  
  .gallery_overlay a img {
    width: auto;
  }

  .gallery_overlay i{
    color: var(--white-color);
    font-size: 36px;
  }
/* news fourteen area */
.news_card_fourteen {
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 0.5);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 30px;
    box-shadow: 10px 16px 24px #F1F1F1;
}

.news_card_fourteen_img {
    overflow: hidden;
}

.news_card_fourteen_img a img {
    width: 100%;
    transition: var(--transition);
}

.news_card_fourteen:hover .news_card_fourteen_img a img {
    transform: scale(1.1);
}

.news_card_fourteen_text {
    padding: 30px 20px;
    position: relative;
}

.news_card_fourteen_list li {
    display: inline-flex;
    font-size: 16px;
    font-weight: 400;
    color: #727178;
    align-items: center;
    transition: var(--transition);
}
.news_card_fourteen_list li a{
    color: #727178;
}
.news_card_fourteen_list li .active{
    color: var(--main-color);
    transition: var(--transition);
}
.news_card_fourteen_list li .active:hover{
    color: #727178;
}

.news_card_fourteen_list li i {
    font-size: 7px;
    color: #727178;
    padding-left: 7px;
    padding-right: 5px;
}

.news_card_fourteen_text h3 {
    padding-top: 16px;
    padding-bottom: 24px;
}

.news_card_fourteen_text h3 a {
    font-size: 24px;
    color: var(--black-color);
    font-weight: 500;
    line-height: 33px;
}

.news_card_fourteen:hover .news_card_fourteen_text h3 a {
    color: var(--main-color);
}


.news_card_fourteen_text a i {
    margin-left: 8px;
}

.news_card_fourteen_top h3 {
    padding: 0;
    font-weight: 500;
}

.news_card_fourteen_top {
    background-color: var(--main-color);
    color: var(--white-color);
    display: inline-block;
    padding: 10px 20px;
    border-radius: 6px;
    position: absolute;
    top: -55px;
    right: 37px;
    z-index: 9;
    text-align: center;
}

.news_fourteen_btn {
    position: relative;
    z-index: 9999;
    padding: 8px 4px;
    transition: var(--main-color);
}

.news_fourteen_btn::before {
    position: absolute;
    content: "";
    background-color: #E8D8FB;
    height: 100%;
    width: 20%;
    z-index: -1;
    left: -6px;
    bottom: 0;
    border-radius: 4px;
    transition: var(--transition);
}

.news_fourteen_btn:hover::before {
    width: 110%;
}
.news_fourteen_btn:hover {
    color: var(--main-color);
}

.qtyTotal {
    background-color: #66676b;
    border-radius: 50%;
    font-family: Open Sans, sans-serif;
    font-size: 11px;
    font-weight: 600;
    height: 18px;
    left: 1px;
    line-height: 18px;
    position: relative;
    top: 4px;
    width: 18px;
    color: #fff;
    display: inline-block;
    text-align: center;
    margin: 0px 6px;
}

.adult-modal-btn {
    background-color: initial;
    color: #fc5b62;
    cursor: pointer;
}

.hotel-detail-border {
    border-bottom: 2px solid #fc5b62;
}


.select-child {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: .5em;
}

.fff {
    align-items: center;
    border: 1px solid gray;
    justify-content: space-around !important;
    border-radius: 4px;
    height: 2.4em;
    overflow: hidden;
    padding: 12px;
}



.css-13cymwt-control 
{
    background: none !important;
    border: none !important;
}

.css-1u9des2-indicatorSeparator{
    background: none !important;
}

.css-1hb7zxy-IndicatorsContainer{
    display: none !important;
}


.cardd a small {
    font-size: 11px;
    padding: 9px;
    position: absolute;
    top: 21px;
    left: 70px;
    font-weight: bold;
}

.form-select{
    /* background: #8b3eea00;
    border: none; */
}
.pictures_grid {
    margin-bottom: 45px;
}

#additional_links {
   
    display: flex;
    justify-content: space-evenly !important;
    align-items: center !important;
}

#additional_links a{
    color: #555;
    float: right;
    font-size: .8125rem;
    margin: 0;
    padding: 8px 0 0;
}


.nav-item a{
    color: white;
     font-weight: bold;
     font-size: 16px;
} 
/* .nav-item a:hover{
   
    border-bottom: 1px solid white;
    padding: 5px 0px;
    color: #8b3eea;
} */
.item-time {
    display: inline-block;
    padding: 0 20px;
    text-align: center;
    vertical-align: middle;
}

.item-time .fa {
    color: #06a803;
    display: block;
    font-size: 50px;
}

.item-time span {
    border-top: 1px dashed #a5a5a5;
    color: #a5a5a5;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    padding: 8px 6px;
}


.fl-flight-container {
    /* display: flex; */
    width: 100%;
    box-shadow: 0 0 2px 0 #00000059;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    /* float: left; */
    width: 100%;
    }
    .fl-detail-left-container p{
        margin-bottom: 0px !important;
    }
    .fl-flight-container:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: linear-gradient(to top,#00000014,#fff);
    z-index: 1;
    }

    .fl-flight-container .flight-name {
        position: absolute;
        left: -25px;
        top: 0;
        bottom: 0;
        padding: 0;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        background-color: #EA0876;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
        }
        .fl-flight-container .flight-name span {
        transform: rotate(-90deg);
        width: auto;
        display: flex;
        /* font-size: 1.8em; */
        font-weight: bold;
        }
        .fl-detail-left {
        display: flex;
        position: relative;
        background: #fff;
        margin-left: 50px;
        padding: 20px 0;
        width: 100%;
        }
        .fl-detail-left {
        display: flex;
        position: relative;
        background: #fff;
        margin-left: 50px;
        padding: 20px 0;
        width: 100%;
        }
        .fl-detail-left .fl-detail-left-container {
        display: flex;
        flex-flow: wrap;
        width: 100%;
        padding: 0 20px;
        margin: auto;
        /* border-right: 1px solid #e4e4e4; */
        }
        .fl-detail-left .fl-flight-schedual {
        width: 100%;
        /* display: flex; */
        padding-top: 10px;
        }
        .fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail h4.left {
            width: 33.33%;
            }
            .fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail h4 {
            font-size: 14px;
            color: #0b0b0b;
            font-weight: 600;
            margin-right: 42px;
            }
            .fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail h4.middle {
            margin: 5px auto;
            }
            
            .fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-direction {
            width: 90%;
            display: flex;
            position: relative;
            margin: auto auto 0px;
            }
            .fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-direction .fl-route-bar {
            width: 100%;
            height: 2px;
            border-bottom: 3px dashed #a4aecc;
            }
            .fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-direction .fl-icon {
            width: 35px;
            margin: auto;
            position: absolute;
            left: 48%;
            top: -8px;
            /* background-color: #fff; */
            z-index: 1;
            text-align: center;
            }
            .fl-width{
            width: 33%;
            }
            
            .fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail p.left {
            width: 33.33%;
            text-align: left;
            }
            .fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail p {
            width: 33.33%;
            font-size: 14px;
            /* color: #545353; */
            font-weight: 700;
            margin: 0;
            margin-right: 36px;
            }
            
            .fl-detail-left .fl-flight-schedual .fl-flight-route {
            display: flex;
            flex-flow: wrap;
            padding-bottom: 16px;
            }
            .fl-flight-schedual .fl-flight-route .fl-route-detail {
            width: 100%;
            display: flex;
            margin-bottom: 10px;
            }
            .fl-flight-container:after {
                content: '';
                position: absolute;
                bottom: -15px;
                left: 50%;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-image: linear-gradient(to top,#fff,#00000014);
                z-index: 1;
                }
                .fl-route-bar .fl-icon img {
                max-width: 100%;
                vertical-align: middle;
                border-style: none;
                }

            .box-top {
                    border: 1px solid #c9c9c9;
                    margin-top: 15px;
                }
                .checkbox-alias {
                    background-color: #d4d4d4;
                    color: #000;
                    cursor: pointer;
                    display: inline-block;
                    font-size: 20px;
                    height: auto;
                    padding: 15px 10px;
                    position: relative;
                    text-align: center;
                    transition: all .25s ease-out;
                    width: 100%;
                    z-index: 1;
                }
                .umrah-package-modal-checkbox {
                    background-color: #EA0876 !important;
                    color: #fff;
                }
                .room-check {
                    margin-right: .4em;
                }
                .single-feature-icon {
    background-color: #287dfa1a;
    border-radius: 50%;
    color: #EA0876;
    font-size: 15px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    text-align: center;
    transition: all .3s;
    width: 30px;
}

a.grid_item, a.grid_item_adventure {
    display: inline;
    margin-bottom: 25px;
}

a.grid_item figure, a.grid_item_adventure figure {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.score strong {
    border-radius: 5px 5px 5px 0;
    line-height: 1;
    padding: 10px;
    background-color: #0054a6;
    color: #fff;
    display: inline-block;
}
a.grid_item figure .score, a.grid_item_adventure figure .score {
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 1;
}
a.grid_item figure img, a.grid_item_adventure figure img {
    height: 24em;
    width: 100%;
    object-fit: cover;
    transform: scale(1.1);
    transition: .3s ease-in-out;
}

a.grid_item .info, a.grid_item_adventure .info {
    background: 0 0;
    background: linear-gradient(180deg, #0000, #000);
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    display: block;
    left: 0;
    padding: 25px 20px 5px;
    position: absolute;
    width: 100%;
    z-index: 1;
}
a.grid_item .info .cat_star {
    font-size: .5625rem;
}


a.grid_item .info h3, a.grid_item_adventure .info h3 {
    color: #fff;
    font-size: 1.125rem;
}

.home-card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.flight-filter12 {
    background: #80808014 !important;
    border-left: 1px solid #80808033;
    border-radius: 5px;
    cursor: pointer;
    padding: 1em;
}

.flight-filter12-active {
    background: #07193a !important;
    color: #fff;
}


.right-sidebar {
    animation: slideIn .3s ease-in-out forwards;
    background-color: #fff;
    box-shadow: -2px 0 5px 0 #0000001a;
    height: 95%;
    left: -50%;
    overflow-y: scroll;
    padding: 20px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    z-index: 999;
}
@keyframes slideIn {
    0% {
      left: -50%;
    }
    100% {
      left: 50%;
    }
  }
  .close-button {
    background: none;
    border: none;
    color: #8b3eea;
    cursor: pointer;
    font-size: 1.3em;
    position: absolute;
    right: 10px;
    top: 10px;
}
.container4 {
    background: #EA0876;
    color: #fff;
}
.item-time .fa {
    color: #EA0876;
    display: block;
    font-size: 50px;
}

.flight-detail-sidebar {
    background: #fff;
    border: .5px solid #d8dcde;
    border-radius: 12px;
    box-sizing: border-box;
    flex: 1 1;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-right: 16px;
    margin-top: 1em;
    padding: 16px;
}

.side-btn{
    position: sticky !important;
    top: 10px;
    width: 100%;
   }

   .rs-slider-progress-bar{
    background-color: #EA0876 !important;
   }
   .rs-slider-handle:before{
    background-color: #EA0876 !important ;
    border: none !important;
   }

   .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 5em;
}

.slide-bg img {
    border-radius: 4px;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 54px !important;
    width: 56px !important;
}
.image-gallery-slide .image-gallery-image {
    width: 100% !important;
    height: 40em !important;
    object-fit: cover !important;
}
.fullscreen .image-gallery-slide .image-gallery-image{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.adult-modal-btn {
    background-color: initial;
    border: none;
    color: #ea0876;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    padding: 1rem;
}
.package-invoice23 {
    background: #8080801f;
    display: flex
;
    justify-content: space-between;
    padding: .5em;
}

.cs-container {
    max-width: 1000px;
    padding: 30px 15px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .cs-invoice.cs-style1 {
    background: #fff;
    border-radius: 10px;
    padding: 50px;
  }
  
  .text-style01 {
    color: #b21f64;
    font-size: 30px;
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .text-style02 {
    color: #000;
    font-size: 30px;
    text-transform: uppercase;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .package_inv_hr {
    border-bottom: 1px solid rgba(128, 128, 128, 0.461);
    width: 99%;
  }
  .cs-bar_list {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
  }
  .checkout-hotel-detail,
  .hotel-checkout-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }  
  .tour_details_boxed h3{
    line-height: normal !important;
  }